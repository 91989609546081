import React, { useEffect, useState } from "react";
import styles from "./addDetails.module.css";
import { useTranslation } from "react-i18next";

function CardComponent() {
  const { t } = useTranslation();
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    // Initial check
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  return (
    <div className={styles.benefits}>
      <div className={styles.benefitsRowOne}>
        <div className={styles.benefitCard}>
          <img src="Images/compass.png" alt="" />
          <p>{t("ads.high_range")}</p>
        </div>
        <div className={styles.benefitCard}>
          <img src="Images/megaphone.png" alt="" />
          <p>{t("ads.direct_advertising")}</p>
        </div>
        <div className={styles.benefitCard}>
          <img src="Images/insert-picture.png" alt="" />
          <p>{t("ads.image_improvement")}</p>
        </div>
      </div>
      <div className={styles.benefitsRowTwo}>
        <div className={styles.benefitCardTwo}>
          <img src="Images/social-network.png" alt="" />
          <p>
            {t("ads.developed_visibility_part1")} {mobile ? <br /> : ""}{" "}
            {t("ads.developed_visibility_part2")}
          </p>
        </div>
        <div className={styles.benefitCardTwo}>
          <img src="Images/tobacconist-shop.png" alt="" />
          <p>
            {t("ads.fast_customer_acquisition_part1")} {mobile ? <br /> : ""}{" "}
            {t("ads.fast_customer_acquisition_part2")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardComponent;
