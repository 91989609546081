import React, { useState, useEffect } from "react";
import styles from "./payment.module.css";
import { message, Tooltip } from "antd";
import BannerStyle from "./banner.module.css";
import { publicRequest } from "../../requestMethod";
import { useTranslation } from "react-i18next";

const Payment = ({ setLocation, formData }) => {
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(undefined);

  const handleClick = () => {
    localStorage.setItem(
      "formValue",
      JSON.stringify({
        selected,
      })
    );
    setLocation("form4");
  };

  const handleBack = () => {
    setLocation("form2");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await publicRequest.get("package");
        const partnerSubscription = response.data.data.filter(
          (d) => d.userType === "PARTNER"
        );

        setData(partnerSubscription);
        setSelected(partnerSubscription?.[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={BannerStyle.banner}>
        <div className={BannerStyle.bannerTitle}>
          {t("payment.welcome_banner_1")}
        </div>
        <div className={BannerStyle.bannerTitle}>
          {t("payment.welcome_banner_2")}
        </div>
      </div>

      <p className={styles.count}>{t("payment.step_count")}</p>

      <div className={styles.choose_pkg}>
        <p>{t("payment.choose_package")}</p>
      </div>

      <div className={styles.main_con}>
        {/* Feature List */}
        <div className={styles.txt_pic}>
          <img src="Images/logo.svg" alt="missing" />
          <div className={styles.list}>
            <div className={styles.listItem}>
              <li>{t("payment.feature_guaranteed_map")}</li>
              <Tooltip
                title={t("payment.tooltip_guaranteed_map")}
                placement="right"
                className={styles.tooltip}
              >
                <img src="Images/tooltip.svg" alt="" />
              </Tooltip>
            </div>
            <div className={styles.listItem} style={{ marginTop: ".5rem" }}>
              <li>{t("payment.feature_preferred_representation")}</li>
              <Tooltip
                title={t("payment.tooltip_preferred_representation")}
                placement="right"
                className={styles.tooltip}
              >
                <img src="Images/tooltip.svg" alt="" />
              </Tooltip>
            </div>
            {/* Add other features similarly */}
          </div>
        </div>

        {/* Cards Section */}
        <div className={styles.maincards_div}>
          <div className={styles.cards_div}>
            {data?.map((_data) => (
              <div
                key={_data._id}
                style={
                  selected?._id === _data._id
                    ? { border: "2px solid #8DC3DF" }
                    : {}
                }
                className={styles.card1}
              >
                <div className={styles.innerCard}>
                  <p style={{ textTransform: "capitalize" }}>
                    {_data.planName}
                  </p>
                  <div className={styles.voucher_text}>
                    <span className={styles.voucher_prices}>
                      {_data.price || 0}*
                    </span>
                    /month
                  </div>
                </div>
                <button
                  onClick={() => setSelected(_data)}
                  className={styles.bttn}
                  style={
                    selected?._id === _data._id
                      ? { border: "2px solid #8DC3DF" }
                      : {}
                  }
                >
                  {selected?._id === _data._id
                    ? t("payment.button_final_step")
                    : t("payment.choose_package")}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Payment Method Section */}
      <div className={styles.form2_main}>
        <div className={styles.reg_process}>
          <p>{t("payment.choose_payment_method")}</p>
        </div>
        <div className={styles.grid}>
          <div className={styles.klarna}>
            <input
              onClick={() => setPaymentMethod("stripe")}
              checked={paymentMethod === "stripe"}
              type="radio"
              id="stripe"
              value="stripe"
              name="payment"
            />
            <label htmlFor="stripe">{t("payment.payment_method_stripe")}</label>
            <img
              src="Images/stripe.png"
              className={styles.icon_pic}
              alt="missing"
            />
          </div>
          <div className={styles.klarna}>
            <input
              onClick={() => setPaymentMethod("paypal")}
              checked={paymentMethod === "paypal"}
              type="radio"
              id="paypal"
              value="paypal"
              name="payment"
            />
            <label htmlFor="paypal">{t("payment.payment_method_paypal")}</label>
            <img
              src="Images/paypal.png"
              className={styles.icon_pic}
              alt="missing"
            />
          </div>
        </div>
        <div className={styles.continue}>
          <button onClick={handleBack}>{t("payment.button_back")}</button>
          <button onClick={handleClick}>
            {t("payment.button_final_step")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
