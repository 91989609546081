import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../src/locale/en.json";
import de from "../src/locale/de.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    de: { translation: de },
  },
  lng: "en", // default language
  fallbackLng: "en", // fallback language
  interpolation: {
    escapeValue: false, // react already escapes by default
  },
});

export default i18n;
