import React from 'react';
import styles from './teamMember.module.css';

const TeamMember = ({ name, role, image, description }) => {
    return (
        <>
            <div className={styles.teamMember}>
                {/*  <div className={styles.memberPhoto} > */}
                <img className={styles.memberPhoto} src={image} alt={name} />

                {/* </div> */}
                <h3 className={styles.memberName}  >{name}</h3>
                <p className={styles.memberRole}>{role}</p>
                <p className={styles.memberDescription}>{description}</p>
            </div>

        </>
    )
}

export default TeamMember