// import React, { useState, useEffect, useRef } from 'react';
// import '../../../src/App.css';
// import { DayPicker } from "react-day-picker";
// import "react-day-picker/style.css";
// const DatePicker = () => {

//     const [dateValue, setDateValue] = useState(null);
//     const [isCalendarOpen, setIsCalendarOpen] = useState(false);
//     const calendarRef = useRef(null);

//     useEffect(() => {
//         // Get the current date
//         const today = new Date();

//         // Add 3 days to the current date
//         today.setDate(today.getDate() + 3);

//         // Set the dateValue state to 3 days ahead
//         setDateValue(today);
//     }, []);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (
//                 calendarRef.current &&
//                 !calendarRef.current.contains(event.target)
//             ) {
//                 setIsCalendarOpen(false);
//             }
//         };

//         document.addEventListener("mousedown", handleClickOutside);
//         return () => document.removeEventListener("mousedown", handleClickOutside);
//     }, []);

//     const toggleCalendar = () => {
//         setIsCalendarOpen((prev) => !prev);
//     };

//     const handleDateSelect = (date) => {
//         setDateValue(date);
//         setIsCalendarOpen(false); // Close the calendar after selecting a date
//     };

//     const monthCaptionStyle = {
//         borderBottom: "1px solid currentColor",
//         paddingBottom: "0.5em",
//     };

//     const handleDayHover = (day) => {
//         setHoveredDay(day);
//     };

//     // Custom inline styles for day cells
//     const getDayCellStyles = (day) => {
//         const isHovered = hoveredDay && hoveredDay.toDateString() === day.toDateString();
//         return isHovered
//             ? {
//                 backgroundColor: 'red',
//                 border: '2px solid red',
//                 color: 'white',
//             }
//             : {};
//     };

//     return (
//         // <div className='custom-datepicker'>
//         //     <DatePickerComponent
//         //         placeholder='Enter Date'
//         //         value={dateValue}
//         //         min={dateValue}

//         //     // style={{
//         //     //     borderRadius: '8px',
//         //     //     backgroundColor: '#f7fcff',
//         //     //     padding: '8px',
//         //     //     fontFamily: 'Arial, sans-serif',
//         //     //     fontSize: '14px',
//         //     // }}

//         //     ></DatePickerComponent>

//         // </div>

//         // <DayPicker
//         //     mode="single"
//         //     selected={dateValue}
//         //     onSelect={setDateValue}
//         //     footer={
//         //         dateValue ? `Selected: ${dateValue.toLocaleDateString()}` : "Pick a day."
//         //     }

//         // />

//         <div className="custom-datepicker" ref={calendarRef}>
//             <div className="input-container" onClick={toggleCalendar}>
//                 <input
//                     type="text"
//                     readOnly
//                     value={dateValue ? dateValue.toLocaleDateString() : ""}
//                     placeholder="Select a date"
//                     className="date-input"
//                 />
//                 {/* <span className="calendar-icon">📅</span> */}
//                 <i className="fa fa-calendar"></i>
//             </div>
//             {isCalendarOpen && (
//                 <div className="calendar-popup">
//                     <DayPicker
//                         mode="single"
//                         selected={dateValue}
//                         onSelect={handleDateSelect}
//                         disabled={{ before: dateValue }}
//                         styles={{
//                             month_caption: monthCaptionStyle
//                         }}

//                         dayStyle={getDayCellStyles} // Apply custom inline styles for each day
//                         onDayMouseEnter={handleDayHover} // Set hover on mouse enter
//                         onDayMouseLeave={() => setHoveredDay(null)} // Remove hover on mouse leave
//                     />
//                 </div>
//             )}
//         </div>

//     )
// }

// export default DatePicker

import React, { useState, useEffect, useRef } from "react";
import "../../../src/App.css";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";

const DatePicker = ({ value, onChange }) => {
  // const [dateValue, setDateValue] = useState(null); // Initially set to null, it will be set by default
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [hoveredDay, setHoveredDay] = useState(null); // For hover effect
  const calendarRef = useRef(null);

  useEffect(() => {
    // Get the current date and set it as the default selected date
    const today = new Date();
    today.setDate(today.getDate() + 3); // Add 3 days to today for the default value
    onChange(today);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the calendar if clicking outside
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  const handleDateSelect = (date) => {
    // Set the selected date and close the calendar
    onChange(date);

    setIsCalendarOpen(false);
  };

  const monthCaptionStyle = {
    borderBottom: "1px solid currentColor",
    paddingBottom: "0.5em",
  };

  // Handle mouse enter event to set hovered day
  const handleDayHover = (day) => {
    setHoveredDay(day);
  };

  // Custom inline styles for day cells
  const getDayCellStyles = (day) => {
    const isHovered =
      hoveredDay && hoveredDay.toDateString() === day.toDateString();
    return isHovered
      ? {
          backgroundColor: "red",
          border: "2px solid red",
          color: "white",
        }
      : {};
  };

  return (
    <div className="custom-datepicker" ref={calendarRef}>
      <div className="input-container" onClick={toggleCalendar}>
        <input
          type="text"
          readOnly
          value={value ? value.toLocaleDateString() : ""}
          placeholder="Select a date"
          className="date-input"
        />
        <i className="fa fa-calendar"></i>
      </div>
      {isCalendarOpen && (
        <div className="calendar-popup">
          <DayPicker
            mode="single"
            selected={value}
            onSelect={handleDateSelect}
            disabled={{ before: new Date() }} // Disable dates before today
            styles={{
              month_caption: monthCaptionStyle,
            }}
            dayStyle={getDayCellStyles} // Apply custom inline styles for each day
            onDayMouseEnter={handleDayHover} // Set hover on mouse enter
            onDayMouseLeave={() => setHoveredDay(null)} // Remove hover on mouse leave
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
