import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import styles from "./aboutStory.module.css";

const AboutStory = () => {
  const { t } = useTranslation(); // Get the translation function

  return (
    <>
      <div className={styles.storyContainer}>
        <div className={styles.titleContainer}>
          <h3 className={styles.storyTitle}>{t("aboutStory.title")}</h3>{" "}
          {/* Use translation */}
        </div>
        <p className={styles.storyText}>
          {t("aboutStory.text1")} {/* Use translation */}
        </p>
        <p className={styles.storyText}>
          {t("aboutStory.text2")} {/* Use translation */}
        </p>
        <p className={styles.storyText}>
          {t("aboutStory.text3")} {/* Use translation */}
        </p>
      </div>

      <Link to="/karim" className={styles.readMore}>
        <span>{t("aboutStory.readMore")}</span> {/* Use translation */}
      </Link>
    </>
  );
};

export default AboutStory;
