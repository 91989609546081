import React, { useRef, useState } from "react";
import data from "./AccordionData";
import "./accordion.css";
import { useTranslation } from "react-i18next";

//  accordionitem component
const AccordionItem = ({ question, answer, isOpen, onClick }) => {
  const contentHeight = useRef();
  return (
    <div className="wrapper">
      <button
        className={`question-container ${isOpen ? "active" : ""}`}
        onClick={onClick}
      >
        <p className="question-content">{question}</p>
        <p className="sing" style={{ marginTop: isOpen ? "-10px" : "" }}>
          {isOpen ? "_" : " +"}
        </p>
      </button>

      <div
        ref={contentHeight}
        className="answer-container"
        style={
          isOpen
            ? { height: contentHeight.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <p
          className="answer-content"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
    </div>
  );
};

const Accordion = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(
    t("questionData", { returnObjects: true })
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchQuery(searchTerm);

    const faqData = t("questionData", { returnObjects: true });

    const filteredData = faqData.filter(
      (item) =>
        item.question.toLowerCase().includes(searchTerm) ||
        item.answer.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredData);
  };

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="main">
      <p className="mainTitle1">{t("accordion.title")}</p>
      <div className="seacrhBox">
        <img src="Images/search.png" className="seacrhBoxIcon" alt="none" />
        <input
          className="seacrhBoxInput"
          type="text"
          placeholder={t("accordion.searchPlaceholder")}
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <p className="mainTitle2">{t("accordion.popularQuestions")}</p>

      <div className="container">
        {filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <AccordionItem
              key={index}
              question={item.question}
              answer={item.answer}
              isOpen={activeIndex === index}
              onClick={() => handleItemClick(index)}
            />
          ))
        ) : (
          <p className="noData">{t("accordion.noResults")}</p>
        )}
      </div>
    </div>
  );
};

export default Accordion;
