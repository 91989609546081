import React from 'react'
import styles from "./title.module.css";
const Title = ({title='text', className='left', padding='15px'}) => {

  let fontSize = className==="left" ? '23px' : '25px';
  return (
    <div className={styles.title} style={{textAlign:className,fontSize:fontSize, paddingTop:padding }}>{title}</div>
  )
}

export default Title