import React from 'react'
import styles from "./unorderList.module.css";
const UnorderList = ({list=[]}) => {
  return (
    <div>
         <ul className={styles.list}> {/* Apply styles if needed */}
        {list.map((item, index) => (
          <li key={index} className={styles.listItem}>{item}</li> // Use key attribute
        ))}
      </ul>
    </div>
  )
}

export default UnorderList