import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Register from "../pages/Register/Register";
import HomePage from "../pages/Home/HomePage";
import SendRequest from "../pages/Ads/SendRequest";
import KarimPage from "../pages/KarimPage/KarimPage"
import AboutPage from "../pages/About/AboutPage";
import IndexPage from "../pages/Index/IndexPage";
import TheApp from "../pages/TheApp/TheApp";
import FAQPage from "../pages/FAQ/FAQPage";
import LegalDisclosure from "../pages/LegalDisclosure/LegalDisclosure";
import TermsCondition from "../pages/TermsConditions/TermsConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import { HelmetProvider } from "react-helmet-async";
import Payment from "../pages/Register/Payment";

const Routepage = () => {

  return (
    <>
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/becomeapartner" element={<HomePage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/ads" element={<SendRequest />} />
          <Route path="/karim" element={<KarimPage />} />

          <Route path="/about" element={<AboutPage />} />

          {/* <Route path="/theapp" element={<TheApp />} /> */}
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/legal-disclosure" element={<LegalDisclosure />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/p" element={<Payment />} />

        </Routes>
      </HelmetProvider>
    </>
  );
};

export default Routepage;
