import React, { useEffect } from "react";
import styles from "./privacyPolicy.module.css";
import Title from "../../components/common/title/Title";
import Description from "../../components/common/description/Description";
import UnorderList from "../../components/common/unorderList/UnorderList";
import { Helmet } from "react-helmet-async";

const privacyData = [
  "IP address",
  "Name of requested website, file, date and time of request",
  "Data volume transferred",
  "Browser type and version",
  "Referrer URL (source URL) from which the user opened the page",
  "Requesting provider plus, the data below transferred by the user’s terminal device",
  "Push handle (for sending push messages)",
  "Location data (GPS location), if the user has granted such permission on the device",
  "Country code",
  "Language",
  "Device data, like manufacturer, device type, advertising-id, operating system, and version of operating system",
];

const mandatoryEntries = [
  "Username",
  "Email address",
  "Password",
  "Date of birth",
  "Sex",
  "Profile picture",
  "Description",
];

const use = ["For our own advertising", "To display personalised advertising."];

const forwardedData = [
  "Advertiser ID",
  "Your published age and gender",
  "Your coarse location",
  "Technical data of the terminal device",
  "NETME usage data",
  "Data of ads shown",
];

const salesAndMarketingPartners = [
  "Ad network operators",
  "Tracking services operators",
  "Affiliate networks",
  "Customer relationship services operators",
  "Other sales & marketing partners",
];

const externalServiceProviders = [
  "External service providers and their agents. NETME UG may order external companies or persons to process customer data.",
  "Affiliated companies as processor. NETME UG may instruct affiliated companies to process customer data.",
  "Affiliated companies as controller. NETME UG may transfer customer data to other members of the company group for internal administrative purposes.",
  "Legal basis for such a transfer is Art. 6 (1) lit. f GDPR with recital nr. 48 GDPR.",
];

const subject = [
  "Subject to changes to its organisation. In conjunction with mergers, acquisitions, bankruptcy or liquidation proceedings, restructuring measures, divestment of some of all of NETME UG’s assets, funding procedures, acquisition of some or all of our business, similar transactions or proceedings or steps having regard to such activities (e.g. due diligence investigations).",
  "Subject to statutory regulations. In order to comply with statutory regulations or authority constraints and in response to legitimate inquiries, court orders and legal procedures.",
  "Subject to enforcing NETME UG’s rights, to preventing fraud and to ensuring safety. To protect and defend the rights, property and/or safety of NETME UG or third parties, including the enforcement of contracts and directives and in support of investigating and preventing cases of fraud.",
  "NETME UG may use aggregate data or data not containing any personally identifiable facts for any purpose. For business or analysis purposes NETME UG may thus forward such aggregate data or data not containing any personally identifiable facts to partners or others by publishing the number of messages sent via NETME on a specific day, for example.",
  "We may share customer data in pseudonymous form with our advertising partners, see section 4.",
];
const sevenPoint = [
  "IP and email addresses are saved to detect spam & scam for as long as the user is active.",
  "Messages are automatically checked for specific keywords but not saved for this purpose.",
  "Personal data such as an active user’s sex, age and location is saved and pooled with other nonpersonal data in order to detect any abnormalities.",
  "Whereas previous phone numbers are saved to check suspicious profiles sent by SMS, such phone numbers cannot be associated with the verified user.",
];

const eightPoint = [
  "Payment data and data regarding reward claims, which we have to retain for tax-related, commercial or other statutory storage obligations.",
  "Data, which you have shared with other users in chats, like messages or pictures (see below)",
];
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 }); // Smooth scroll to top
  }, []);
  return (
    <>
      <Helmet>
        <title>Privacy Policy - NETME</title>
        <meta
          name="description"
          content="NETME: Your Passport to Offline Connections"
        />
      </Helmet>

      <div className={styles.main}>
        <Title
          title="Privacy Policy – NETME"
          className="center"
          padding="80px"
        />

        <Description des="This privacy statement is valid for the mobile app NETME, the site www.netme.eu, and additional services offered by NETME UG which refer to this privacy statement." />
        <Description des="Your privacy is important to NETME. We only use full personal data for providing the NETME platform optimisation. We will never share sensitive data like your name or profile picture with third parties." />

        <Title title="Introduction" />
        <Description des="NETME UG, Stefan-George-Ring 55, 81929 München, Germany (“NETME UG” or “we/us/our”) owns the domain “www.netme.eu” and all of its sub-pages (“NETME domain”) where it provides mobile app NETME (“NETME app”) available for download for various operating systems which is its principal means of running a platform for chatting with, getting to know and meeting other people (“NETME”). NETME UG is the data controller." />
        <Description des="The service offering is based on the freemium model allowing a NETME user (“user” or “you”) to run the NETME app to create a profile (“user profile”), upload images, exchange messages etc. with other interested users and their user profiles for free. Users have access to various functions such as Chat, Nearby Localities or Send Invitations and many other NETME functions. Added to that, NETME offers a chargeable premium service for users who wish to gain access to further unique functions." />
        <Description des="NETME’s offering also lets you meet real people in your area and therefore classifies as a so-called network service. Everybody aged 16 or older may register. Collecting, processing and using your data is based on the provisions of the Federal Telemedia Act (TMG) and the data privacy laws, the latter particularly including the Federal Data Protection Act (BDSG) and as of 15 July 2018 – the General Data Protection Regulation (GDPR." />
        <Description des="This privacy statement explains how we will handle your personal data. Personal data is discrete information about private or objective conditions of a specific or identifiable natural person. Personal data includes, but is not limited to a person’s name, birthday or the e-mail address and usage data such as the IP address. Another purpose of the privacy statement is to inform users about the data collected, saved and processed and how NETME UG ensures the protection and safety of personal data." />
        <Description des="NETME UG does not insist on the use of real names. Users may specify a nickname instead of their real name." />
        <Description des="This privacy statement breaks down into the following sections:" />

        <Title title="Privacy Statement" />
        <div className={styles.privacyContainer}>
          {/* <h4 className={styles.mainHeading}>Privacy Statement</h4> */}

          <section>
            <h2 className={styles.sectionHeading}>
              1. How is my data collected, processed, and used when I download
              the NETME app?
            </h2>
            <ul className={styles.sublist}>
              <li>1.1. NETME app download</li>
              <li>1.2. Installation and access rights</li>
            </ul>
          </section>

          <section>
            <h2 className={styles.sectionHeading}>
              2. How does NETME collect, process and use my data if I just visit
              the NETME domain or the NETME app but do not create a user
              profile?
            </h2>
            <h2 className={styles.sectionHeading}>
              3. How does NETME collect, process and use my data when I use the
              functions of the NETME app or the NETME domain after creating a
              user profile?
            </h2>
            <ul className={styles.sublist}>
              <li>3.1. Mandatory entries for creating a user profile</li>
              <li>3.2. Location data</li>
              <li>3.3. Location data</li>
            </ul>
          </section>

          <section>
            <h2 className={styles.sectionHeading}>
              4. How does NETME use my data for advertising?
            </h2>
            <ul className={styles.sublist}>
              <li>4.1 How is my data used for NETME’s own advertising?</li>
              <li>
                4.2 How does NETME use my data for personalised advertising?
                <ul className={styles.sublist}>
                  <li>
                    4.2.1. Which data is used for advertising and who is it
                    given to?
                  </li>
                  <li>4.2.2. What is an advertiser ID and how is it used?</li>
                  <li>4.2.3. Using location data</li>
                  <li>
                    4.2.4. Legal basis for the use of personal data for
                    advertising purposes
                  </li>
                </ul>
              </li>
              {/* <li>3.3. Legal basis for the use of personal data for advertising purposes</li> */}
            </ul>
          </section>

          <section>
            <h2 className={styles.sectionHeading}>
              5. How are social media and Like Buttons integrated?
            </h2>
            <ul className={styles.sublist}>
              <li>5.1 Facebook-Connect</li>
            </ul>
          </section>

          <section>
            <h2 className={styles.sectionHeading}>
              6. How is data disclosed or forwarded?
            </h2>
            <ul className={styles.sublist}>
              <li>6.1 General notes on the forwarding of personal data</li>
              <li>6.2 Categories of data recipients</li>
            </ul>
          </section>

          <section>
            <h2 className={styles.sectionHeading}>
              6. How is data processed and used to prevent fake profiles,
              illegal activity, and spam?
            </h2>
            <ul className={styles.sublist}>
              <li>6.1. How is data deleted and backup created?</li>
            </ul>
          </section>

          <section>
            <h2 className={styles.sectionHeading}>
              7. How is data processed and used to prevent and fight
              fake-profiles, illegal activity and spam and to secure the
              integrity and stability of the NETME platform?
            </h2>
          </section>
          <section>
            <h2 className={styles.sectionHeading}>
              8. How is data deleted and back-ups created?
            </h2>
          </section>
          <section>
            <h2 className={styles.sectionHeading}>
              9. How does NETME ensure data integrity?
            </h2>
          </section>

          <section>
            <h2 className={styles.sectionHeading}>
              10. User rights: consent and withdrawal of consent, information,
              correction, deletion, transfer
            </h2>
            <ul className={styles.sublist}>
              <li>10.1. Consent and withdrawal of consent</li>
              <li>10.1. Information, correction, deletion, transfer</li>
            </ul>
          </section>

          <section>
            <h2 className={styles.sectionHeading}>
              11. Changes to this privacy statement
            </h2>
          </section>

          <section>
            <h2 className={styles.sectionHeading}>
              12. Privacy contact person and data protection officer
            </h2>
          </section>
        </div>

        <Title title="1. How is my data collected, processed and used when I download the NETME app?" />
        <Title title="1.1. NETME app download" />
        <Description des="When you download the NETME app, the relevant data will be transferred to the app store operator concerned. Depending on the app store, the set of data may include the app store user’s e-mail address or customer number, the time of download and a unique device ID, for example. However, NETME UG has no influence on and is not the controller for processing this data. The privacy statements of the relevant app store operator apply." />

        <Title title="1.2. Installation and access rights" />
        <Description des="NETME needs various access pathways and data to ensure the technical operability of the NETME app and to provide the services you can run using the NETME app." />
        <Description des="Depending on which operating system you use, the installer may already ask your permission to access specific functions and pieces of information. Such access rights cover the location, messages, mobile data and others." />
        <Description des="The user preferences of some terminal devices allow user to manually withdraw some of these rights. If you do, please bear in mind that not granting these rights may render the NETME app partly or completely useless. Access rights will be checked before or after the installation, depending on your app version." />

        <Title title="2. How does NETME collect, process and use my data if I just visit the NETME domain or the NETME app but do not create a user profile?" />
        <Description des="Unless the user explicitly consents, NETME UG uses the personal data disclosed by the user or generated when using NETME without creating a user profile solely for performance of the contractual relationship and as is necessary for the purposes of the legitimate interests pursued by NETME UG use as described in this privacy statement." />
        <Description des="Except for the data listed below, NETME does not collect any personal data if you simply visit the NETME domain (starting on a so-called landing or home page) or install the NETME app without creating a user profile." />
        <Description des="The set of data contains:" />

        <UnorderList list={privacyData} />

        <Description des="This data is required to use the NETME domain or to install the NETME app as such or they are used for analysis for statistical purposes in order to optimise NETME. This data is used to prevent and fight fake-profiles, illegal activity and spam and to secure the integrity and stability of the NETME platform. For this purpose, we may store the full data for up to 90 days." />

        <Description des="Collecting, processing and using the location data is necessary for the user to run the so-called “location-based service” which provides registered users with suggestions tailored to the user’s location. Sections 3.2." />

        <Title title="3. How does NETME collect, process and use my data when I use the functions of the NETME app or the NETME domain after creating a user profile?" />

        <Description des="Unless the user explicitly consents, NETME UG uses the personal data disclosed by the user or generated when using NETME after creating a user profile solely for performance of the contractual relationship and as is necessary for the purposes of the legitimate interests pursued by NETME UG use as described in this privacy statement. Proper use of NETME to the full extent requires the user to create a user profile. This involves entering further personal data which NETME UG uses to provide the service agreed." />

        <Title title="3.1 Mandatory entries for creating a user profile" />
        <Description des="You must enter the following details to register and create a user profile:" />

        <UnorderList list={mandatoryEntries} />
        <Description des="We collect, process and use the data you enter so that you can use NETME. Among other things it helps us address and authenticate you, personalise your profile and (in pseudonymous form) to show you ads (see section 4)." />
        <Description des="We delete this data when you delete your account. The legal basis for this processing of personal data is Article 6 (1) b) or Article 6 (1) f) of the EU General Data Protection Regulation (GDPR)." />

        <Title title="3.2 Location data" />

        <Description des="NETME’s offering includes so-called location-based services which display specific localities to the user’s location. A user can therefore be shown other users in the vicinity. To be able to provide these functions within the NETME app, NETME collects location data by means of GPS of the terminal device concerned as well as location data of nearby radio access nodes. Provided that the site survey is enabled, and permission is granted, the location will be periodically transferred to NETME UG and will be respectively processed and used." />
        <Description des="Before collection of location data, users must allow the collection of location data. The location data is then processed in accordance with this privacy statement. If activated and granted, the location data is transferred to NETME UG at regular intervals to be processed and used." />
        <Description des="Users can change this functionality at any time by displaying the Preferences of their terminal device’s operating system at any time to either allow or disallow the collecting of location data or within the app itself." />
        <Description des="Location data will also be used for advertising purposes, if the user has not objected to this in the app settings. (See section 4 for information about advertising)." />
        <Description des="We store location data unshortend for up to 14 days and shortened up to 90 days. The legal basis for this processing of personal data is Article 6 (1) b) or f) of the EU General Data Protection Regulation (GDPR)." />

        <Title title="3.3 Payment data" />
        <Description des="External payment service providers handle the transactions involved when a user does any In-App purchases via NETME, buys credits or subscribes to our chargeable premium service. NETME UG neither collects nor processes any payment data of shopping transactions. Instead, the payment service providers take care of entering and processing the payment data directly. All that NETME and the payment service providers do is to exchange technical data (e.g. the transaction ID) in order to validate the purchases. We save this data until your user account is deleted or beyond this point, until a time when the data is no longer subject to any tax-related, commercial or other statutory storage obligations. The legal basis for this processing of personal data is Article 6 (1) b) of the EU General Data Protection Regulation (GDPR)." />

        <Title title="4. How does NETME use my data for advertising?" />

        <Description des="We use data for advertising in two regards:" />
        <UnorderList list={use} />

        <Title title="4.1 How is my data used for NETME’s own advertising?" />
        <Description des="We display ads of our own to our users on our platform and also inform them by e-mail, e.g. about one-off campaigns, discounted package prices or similar offers. In order to be able to display suitable offers, we use information provided by the user (such as profile data) and information that we automatically receive through the use of our platform." />
        <Description des="The user can object to the use for advertising purposes at any time by deactivating personalised advertising in the app settings under Privacy and or in his device settings or in the settings on the website." />
        <Description des="The data transfer takes place exclusively as described under 6." />
        <Description des="The legal basis for this processing is the legitimate interest of NETME according to Art. 6 I lit. f DSGVO." />

        <Title title="4.2 How does NETME use my data for personalised advertising?" />
        <Description des="NETME UG decided to run NETME as a freemium service which lets every user obtain a basic NETME version free of charge. Users of the free version will be shown ads. The ads shown in NETME contain our own advertising matters (such as the premium products of NETME) or content ordered by an advertising firm in so-called campaigns. Campaigns may be personalised to tailor them to specific user groups. Some ads shown in NETME are mediated by external service providers." />

        <Title title="4.2.1 Which data is used for advertising and who is it given to?" />
        <Description des="NETME UG can use NETME to show third-party ads which may be tailored to the users’ assumed specific interests (personalised ads) or targeted to certain user groups grouped by age, location or gender. The users can object to the use of its data in the privacy settings of the app or the device settings. We and our advertising partners will only use non-sensitive data, that the you have published on NETME and information about your device and your connection. Under no circumstances will we share your name, your e-mail address, your profile picture or your exact location or similar data that allows a direct inference of your real person with our advertising partners." />
        <Description des="Whenever such personalised ads are shown, the third-party provider whose ad is shown in NETME or whose services are used to show ads of other third parties the following pseudonymous data may be transferred (“forwarded data”):" />
        <UnorderList list={forwardedData} />
        <Description des="Forwarded data is disclosed to the following categories of recipients for the purposes explained:" />
        <UnorderList list={salesAndMarketingPartners} />
        <Description des="You can object to this processing for the future at any time by going to the privacy settings of the NETME app or the device settings. You can also reset your Advertising ID in your device settings. In that case a new Advertising ID is created which will not be joined with older data. With this you can ensure, that you will not see any ads based on the forwarded data before you have objected to the transfer." />
        <Description des="All our partners are obligated to process forwarded data only on accordance with the high data protection principles of the European Union and only for advertising purposes. This means, that all partners are obligated no not identify the real user / or remove the Pseudonymity of the data." />

        <Title title="4.2.2. What is an advertiser ID and how is it used?" />
        <Description des="For tracking and advertising, the NETME app uses the so-called advertiser ID, i.e. the “Advertiser Identifier” (IDFA) for offerings of Apple Inc. and the “Android Advertiser ID” (advertiser ID) for offerings of Google. Inc. (jointly the “advertiser ID(s)”). These advertiser IDs are unique nonpermanent numbers identifying a specific terminal device. They are provided by iOS for offerings of Apple Inc. or by Android for offerings of Google Inc. As opposed to vendor advertiser IDs (generated by latest-generation operating systems and identical for the apps of just one provider), the advertiser ID is the same as the one transferred to other companies when running their apps. The user can deactivate the generation of the Advertiser-ID. On an apple device this can be done in the iOS preferences, under “Privacy” — “Ads” by enabling the option “no ad tracking”; for a user owning an Android this can be achieved by opening the “Google Settings” (or first “Settings” and then “Google”), clicking on “Display” and ticking the option “Disable personalised ads. The Advertiser ID is pseudonymous and NETME UG will not be joined with other user data to identify him for advertising purposes." />
        <Description des="The Advertiser ID will be used by NETME UG and our advertising partner as described above to show you personalised advertising and to analyse this advertising. You can always object to this pseudonymous use by disabling the Advertiser ID as described above or objecting in the privacy settings of our app." />
        <Description des="For further details and Apple Inc.’s privacy policy visit: https://www.apple.com/legal/privacy/" />
        <Description des="For further details and Google Inc.’s privacy policy visit: https://www.google.de/intl/de/policies/privacy/" />

        <Title title="4.2.3. Using location data" />
        <Description des="Apart from collecting the location data by running GPS Location to enable the location-based services, NETME UG collects and uses other location data to show the user ads in NETME tailored to the user’s location and profile. NETME UG will never forward the exact location to our advertising partners. To protect your privacy all provided location data is coarse by around 2 km. To our knowledge with this data the identification of an individual is not or only with an unreasonable and illegal measures possible." />
        <Description des="For users that are sensitive to privacy question, we offer a separate option in the apps privacy settings to object of the use of location data for the future." />

        <Title title="4.2.4. Legal basis for the use of personal data for advertising purposes" />
        <Description des="NETME UG bases the processing of sharing of the above data on the performance of the contract with you (Article 6 (1) b) GDPR in connection with Nr. 11.2. of our Terms of Service), your consent (Article 6 (1) a) GDPR) and the legitimate interests pursued by us (Article 6 (1) f) GDPR)." />
        <Description des="We offer our users a service under the Freemium model, which means, that certain parts of NETME are free to use. In Exchange for using the NETME platform without charge, NETME UG displays personalised advertisings and process and shares pseudonymous data as specified above." />
        <Description des="Pre-requirement for all data transfer is that the recipient ensures by contractual, legal, technical and organisational measure that the data processing is always in compliance with privacy law." />
        <Description des="The users consents to the data use and transfer to the recipients above when using the free version of the app." />
        <Description des="We have decided to only share data, which (even in the event of an unlawful disclosure or by hacking of our partners) is not sensitive for individual users. We thus only share data in pseudonymous form and only data that the user has made public or is, by its nature, not sensitive like device and network data." />
        <Description des="NETME will only pass on data that has been voluntarily made public on the NETME platform by the user and therefore shows a low level of sensitivity to the user and device or connection data which, due to their very nature, have are not sensitive." />
        <Description des="5. How are social media and Like buttons integrated?" />
        <Description des="The NETME domain contains links to NETME UG accounts with the following social networks: Instagram and Facebook. After clicking on the embedded picture, you will be taken to the associated Instagram and Facebook page. That is to say that user data will not be transferred to the provider until you click on a link. The legal basis for this processing of personal data is Article 6 (1) a) of the EU General Data Protection Regulation (GDPR)." />
        <Description des="Provided that you and your user profile are logged in to the social destination network at the time, your click on the button will be associated with your visit to NETME." />
        <Description des="If you do not wish the social media to collect data via the NETME domain, we recommend that you log off your social network before you visit the NETME domain. But even so, one with an ID will be uploaded when you click on the button and run NETME. This function may therefore collect data and create a profile which may then be traced back to a specific natural person. If you do not wish this to happen, just visit the NETME domain and click on the correct option to disable the function. Or you can set your browser to never accepting any cookies; please note that this may restrict the functionality of NETME." />
        <Description des="For details on how these sites handle personal data, please read the privacy policies of the relevant providers." />
        <Description des="Facebook" />
        <Description des="Visit https://de-de.facebook.com/about/privacy/ to read Facebook’s privacy policy (operated by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA)." />
        <Description des="Instagram" />
        <Description des="Visit https://www.instagram.com or https://help.instagram.com/155833707900388 to read Instagram’s privacy policy (operated by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA)." />
        <Title title="5.1 Facebook-Connect" />
        <Description des="NETME UG allows you to run Facebook-Connect to log in to NETME. To log in, you will be forwarded to the Facebook page (operated by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA) where you use your user data to log in if not logged in yet. A single click then links your Facebook profile to your NETME user profile. This link then automatically provides NETME UG with your Facebook data, i.e. your e-mail address and other details stored with your public Facebook profile. Your public Facebook profile shows all data you have made publicly known via Facebook. A public Facebook profile normally consists of the following details: your name, profile picture, sex, language, country, age bracket, Facebook ID, friends, likes and your relationship status." />
        <Description des="Of these details provided by Facebook, NETME UG just uses your e-mail address, Facebook ID, profile picture and your date of birth. These details go into your user profile. For further information about Facebook-Connect and its privacy settings please visit https://www.facebook.com/policy.php and read Facebook’s privacy rules and terms of use." />
        <Description des="The legal basis for this processing of personal data is Article 6 (1) a) of the EU General Data Protection Regulation (GDPR)." />
        <Title title="6. How is data disclosed or forwarded?" />
        <Description des="NETME UG does not forward the personal data of its users to third parties, unless the user explicitly agrees, or such forwarding is required or permitted by law." />

        <Title title="6.1 General notes on the forwarding of personal data" />
        <Description des="User and other data" />
        <Description des="NETME UG may transfer customer data to the following third parties if such data concern the execution of the contract:" />
        <UnorderList list={externalServiceProviders} />
        <Description des="Other disclosures" />
        <Description des="NETME UG may transfer customer data to the following third parties if such data concern the execution of the contract:" />

        <UnorderList list={subject} />
        <Title title="6.2 Categories of data recipients" />

        <Description des="NETME UG gives third parties access to your personal data only if this is necessary for our own business purpose (especially performance of contract), with your consent, if is covered by our own legitimate interest, or when we are obliged to on legal grounds, by court order, or at the request of another official authority." />
        <Description des="We work together with external service providers for our data processing. This is usually carried out on an order processing basis, whereby we remain responsible for data processing. We review each of these service providers beforehand with regard to the measures they have undertaken to ensure data protection and data security, thereby safeguarding the contractual provisions as stipulated by law for the protection of the personal data." />

        <Description des="Depending on which services are provided, NETME UG may also ask affiliated companies to act as data processing services provider for providing some or all of the services offered its customers." />

        <Title title="7. How is data processed and used to prevent and fight fake-profiles, illegal activity and spam and to secure the integrity and stability of the NETME platform?" />
        <Description des="Personal data is used as described below to prevent and fight fake-profiles, illegal activity and spam and to secure the integrity and stability of the NETME platform." />

        <Title title="8. How is data deleted and back-ups created?" />
        <Description des="Deleting a user profile (attention: not by deleting the NETME app!) or notifying us accordingly deletes all of that user’s data. Apart from that, users can remove profile details themselves or ask us to do so at any time." />
        <Description des="Exempt of such a deletion is data, that we have to retain by statutory storage obligations, for winding up the contract and for performance of contract with other users. For example, we will not promptly delete the following data:" />
        <UnorderList list={eightPoint} />
        <Title title="When do we delete data, that you have shared with other users?" />
        <Description des="We delete data which is accessible in your public profile, like profile pictures, stream pictures and profile data, promptly as specified above." />
        <Description des="We can’t delete data that you have shared non-publicly with other users without infringing the rights of this other user. Thus, we can delete chat data only after both users have delete their profile." />

        <Title title="How long will it take to delete the data?" />
        <Description des="Such details will be immediately removed from the productive databases and, thus from the NETME interface; however, it may take up to 14 days until the data is removed from all of NETME’s server layers, cache memories and back-up databases." />
        <Description des="If deletion is not possible, we will anonymise the data or restrict the processing." />
        <Description des="In case certain facts lead to the suspicion that a user profile uses NETME illegally or contrary to the contract with us, we reserve the right to check and perhaps save profile files before deleting them. This is to protect the users of NETME UG’s offerings." />
        <Description des="Irrespective of data deleted because of user-deleted profiles, NETME automatically and periodically deletes historical data no longer needed to perform the contract (e.g. historical location details and IP data)." />

        <Title title="9. How does NETME ensure data integrity?" />
        <Description des="The trust of its users and protecting their data is of great importance to NETME UG. To prevent unauthorised access to or disclosure of user data and to ensure the integrity and appropriate use of the data, NETME has appropriate physical, technical and administrative procedures in place to protect the information collected by NETME UG." />
        <Description des="To provide highest-level protection of the user data, the offerings of the NETME domain or NETME app rely on a secure SSL connection that allows the transfer of nothing but encrypted data." />

        <Title title="10. User rights: consent and withdrawal of consent, information, correction, deletion, transfer" />
        <Title title="10.1 Consent and withdrawal of consent" />
        <Description des="When registering with NETME, users agree to collecting, saving and processing their personal data subject to this Privacy Statement and our General Terms and Conditions. Users will have to explicitly consent to this procedure when they create their user profile. NETME UG logs both the granting of the consent and the entire process of user profile creation." />
        <Description des="Users can withdraw their consent at any time and with future effect. Methods of doing so is by deleting their user profile (not the NETME app), going to the specified user profile places in the NETME app or NETME domain or by sending us an email, fax or letter to:" />
        <Description des="LETTER: NETME UG, Stefan-George-Ring 55, 81929, Munich, Germany" />
        <Description des="EMAIL: datenschutz@netme.eu" />

        {/* EmialLink */}
        <Title title="10.2 Information, correction, deletion, transfer" />
        <Description des="Subject to the applicable laws, users have a right to being informed about their personal data stored and a right to correcting, deleting and/or transferring such data, if so." />
        {/* EmialLink */}
        <Description des="To exercise these rights, users may send an email to datenschutz@netme.eu." />
        <Description des="You have the right to lodge a complaint with a supervisory authority." />

        <Title title="11. Changes to this privacy statement" />
        {/* link */}
        <Description des="NETME UG will update this privacy statement as necessary. Using the user data is subject to the most recent version available for download at https://www.netme.eu/privacy. An email will be sent to the email address stored with the user profile to inform users about major changes to this statement (e.g. changes to the user rights, new functions, new contact persons etc.). By continued access to and use of NETME after such changes have taken effect, users declare their consent to being legally bound to the revised privacy statement." />
        <Title title="12. Privacy contact person and data protection officer" />
        {/* link */}
        <Description des="If you have questions concerning the collection, processing or use of your personal data, if you wish to be informed, to correct, bar or delete any data or to withdraw a previous consent, please do one of the following: delete your user profile (not the NETME app) or go to the specified user profile places in the NETME app or NETME domain and modify your user profile there or send us an email or letter to:" />
        <Description des="NETME UG, Stefan-George-Ring 55, 81929, Munich, Germany" />
        <Description des="EMAIL: datenschutz@netme.eu" />
      </div>
    </>
  );
};

export default PrivacyPolicy;
