import React, { useState, useEffect } from "react";
import styles from "./header.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation(); // Get current route
  const [menuActive, setMenuActive] = useState(false); // State to manage mobile menu

  // const handleClick = () => {
  //   navigate("/register");
  //   closeMenu();
  // };

  const handleClick = () => {
    if (
      location.pathname === "/" ||
      location.pathname === "/faq" ||
      location.pathname === "/about"
    ) {
      // Redirect to app store or play store
      window.open("https://play.google.com/store", "_blank");
    } else if (location.pathname === "/becomeapartner") {
      // Navigate to Become Partner page
      // navigate("/becomeapartner");
      navigate("/register");
    } else {
      // Default action (Register page)
      // navigate("/register");
      navigate("/");
    }
    closeMenu(); // Close the mobile menu after navigating
  };

  const handleLogoClick = () => {
    navigate("/");
    closeMenu(); // Close the mobile menu after navigating
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive); // Toggle the menu
  };

  const closeMenu = () => {
    setMenuActive(false); // Close the menu
  };

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth >= 767) {
        setMenuActive(false);
      }
    };

    updateSize(); // Run on initial render
    window.addEventListener("resize", updateSize); // Add resize listener
    return () => window.removeEventListener("resize", updateSize); // Cleanup listener
  }, []);

  const getButtonText = () => {
    if (
      location.pathname === "/theapp" ||
      location.pathname === "/faq" ||
      location.pathname === "/about"
    ) {
      return t("download_app");
    } else if (location.pathname === "/becomeapartner") {
      return t("become_partner");
    }
    return t("download_app");
    // return "REGISTER NOW";
  };

  useEffect(() => {
    setMenuActive(false);
  }, [location.pathname]);

  return (
    <>
      <div className={styles.container}>
        {/* <div className={location.pathname === "/" ? styles.header : styles.header2}> */}
        <div className={styles.header2}>
          <div className={styles.logo}>
            <img src="Images/logo.svg" alt="Logo" onClick={handleLogoClick} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className={`${styles.menu} ${menuActive ? styles.active : ""}`}
            >
              <Link to="/">
                <span
                  className={`${styles.spanOne} ${
                    location.pathname === "/" ? styles.activeMenu : ""
                  }`}
                >
                  {t("app")}
                </span>
              </Link>
              {/* <Link to="/theapp">
              <span
                className={`${styles.menuSpan} ${location.pathname === "/theapp" ? styles.activeMenu : ""
                  }`}
              >
                The App
              </span>
            </Link> */}
              <Link to="/faq">
                <span
                  className={`${styles.menuSpan} ${
                    location.pathname === "/faq" ? styles.activeMenu : ""
                  }`}
                >
                  {t("faq")}
                </span>
              </Link>
              <Link to="/about">
                <span
                  className={`${styles.menuSpan} ${
                    location.pathname === "/about" ? styles.activeMenu : ""
                  }`}
                >
                  {t("about")}
                </span>
              </Link>
              <Link to="/becomeapartner">
                <span
                  className={`${styles.menuSpan} ${
                    location.pathname === "/becomeapartner"
                      ? styles.activeMenu
                      : ""
                  }`}
                  id={styles.become}
                >
                  {t("become_partner")}
                </span>
              </Link>
              <p></p>
            </div>
            <div className={styles.btnBox}>
              <button className={styles.menuButton} onClick={handleClick}>
                {getButtonText()}
              </button>
              {/* <button className={styles.menuButton} onClick={handleClick}>
                REGISTER NOW
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.container1}>
        {/* Mobile Menu Toggle */}
        <div style={{ width: "50px", marginLeft: "2rem", marginTop: ".4rem" }}>
          <img src="Images/logo.svg" alt="Logo" onClick={handleLogoClick} />
        </div>
        <div
          className={`${styles.menuToggle} ${menuActive ? styles.active : ""}`}
          onClick={toggleMenu}
        >
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>

        {/* Navbar */}
      </div>
      <div
        className={
          menuActive ? styles.mobileMenuActive : styles.mobileMenuDeactive
        }
      >
        <div className={styles.mobileMenu}>
          <Link to="/">
            <span
              className={`${styles.spanOne} ${
                location.pathname === "/" ? styles.activeMenu : ""
              }`}
            >
              {t("app")}
            </span>
          </Link>

          <Link to="/faq">
            <span
              className={`${styles.menuSpan} ${
                location.pathname === "/faq" ? styles.activeMenu : ""
              }`}
            >
              {t("faq")}
            </span>
          </Link>

          <Link to="/about">
            <span
              className={`${styles.menuSpan} ${
                location.pathname === "/about" ? styles.activeMenu : ""
              }`}
            >
              {t("about")}
            </span>
          </Link>

          <Link to="/becomeapartner">
            <span
              className={`${styles.menuSpan} ${
                location.pathname === "/becomeapartner" ? styles.activeMenu : ""
              }`}
              id={styles.become}
            >
              {t("become_partner")}
            </span>
          </Link>

          <button className={styles.menuButtonMobile} onClick={handleClick}>
            {getButtonText()}
          </button>
          {/* <button className={styles.menuButtonMobile} onClick={handleClick}>
              REGISTER NOW
            </button> */}
        </div>
      </div>
    </>
  );
};

export default Header;
