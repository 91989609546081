import React from 'react'

const Button = ({text='text', bgColor='white', textColor='white', click = ()=>{}}) => {
  return (
    <button onClick={click} style={{background:bgColor , fontSize:'1.5rem', paddingTop:'0.625rem', paddingBottom:'0.625rem', paddingLeft:'1.563rem', paddingRight:'1.563rem'
        , borderRadius:".8rem", color:textColor, fontFamily:'GalanoGrotesque-SemiBold'
    }}>
        {text}
    </button>
  )
}

export default Button