import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import Styles from "./ourVision.module.css";

const VisionComponent = () => {
  const { t } = useTranslation(); // Get the translation function

  return (
    <>
      <div className={Styles.visionContainer}>
        <div className={Styles.visionContainerLeft}>
          <p className={Styles.visionTitle}>{t("vision.title")}</p>{" "}
          {/* Use translation */}
          <div className={Styles.visionImage}>
            {/* Replace with an actual image or SVG */}
            <img src="Images/OurVision.png" alt={t("vision.title")} />{" "}
            {/* Use translation */}
          </div>
        </div>

        <div className={Styles.visionContainerRight}>
          <div className={Styles.visionText}>
            <p>
              {t("vision.text")} {/* Use translation */}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisionComponent;
