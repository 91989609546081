import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function HomePage() {
  const navigate = useNavigate();
  const [imageSource, setImageSource] = useState("Images/mobileThree.svg");
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setInterval(() => {
      setImageSource((prevImageSource) =>
        prevImageSource === "Images/mobileThree.svg"
          ? "Images/mobileFour.svg"
          : "Images/mobileThree.svg"
      );
    }, 4000);
    return () => clearInterval(timeout);
  }, []);

  const handlePartnerClick = () => {
    navigate("/register");
  };
  const handleAdsClick = () => {
    navigate("/ads");
  };
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animations should only happen once
    });
    AOS.refresh(); // Ensure animations are recalculated
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 }); // Smooth scroll to top
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("home.partnerTitle")} - NETME</title>
        <meta
          name="description"
          content="NETME: Your Passport to Offline Connections."
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.banner}>
          <div className={styles.bannerLeft}>
            <div className={styles.bannerHeading}>
              <p>{t("home.partnerTitle")}</p>
              {/* <p>WITH NETME!</p> */}
            </div>
            <div className={styles.description}>
              <p>{t("home.description1")}</p>
              {/* <p>{t("home.description2")}</p> */}
              <br></br>
              <p style={{}}>{t("home.description2")}</p>
            </div>
            {/* <span className={styles.visibleonmobile}>Drive more customers to your restaurant, bar, or coffee shop—without the hassle of active marketing. More visibility, more visits, more sales. </span> */}
            <span className={styles.visibleonmobile}>
              {t("home.description1")}{" "}
            </span>

            <div className={styles.bannerButton}>
              <button onClick={handlePartnerClick}>
                {t("home.partnerNow")}
              </button>
              <button
                onClick={handleAdsClick}
                className={styles.bannerButtonAds}
              >
                {t("home.startWithAds")}
              </button>
            </div>
          </div>
          <div className={styles.bannerRight}>
            <img src="Images/LeftWebp.webp" alt="mobileOne" />
            <img
              src="Images/RightWebp.webp"
              alt="mobileTwo"
              className={styles.bannerImageRight}
            />
          </div>
        </div>

        <div className={styles.fullWidth}>
          <div className={styles.benifits1}>
            <div className={styles.benifitsHeading}>
              <p>{t("home.benefitsTitle")}</p>
            </div>
            <div className={styles.cardLayout}>
              <div className={`${styles.cardRow} ${styles.cardRowMargin}`}>
                <div className={styles.card}>
                  <div className={styles.cardImage}>
                    <img src="Images/Group1.png" alt="" />
                  </div>
                  <div className={styles.cardContent}>
                    <div
                      className={`${styles.cardContentHeder} ${styles.headerWidth}`}
                    >
                      <p>{t("home.attractCustomers")}</p>
                    </div>
                    <div className={styles.cardContentDetails}>
                      <p>{t("home.businessAppears")} </p>
                      <p>{t("home.usersFindEasy")} </p>
                      <p>{t("home.meetingPlaceNearby")}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles.cardImage}>
                    <img src="Images/Group2.png" alt="" />
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.cardContentHeder}>
                      <p>{t("home.noMarketingEffort")}</p>
                    </div>
                    <div className={styles.cardContentDetails}>
                      <p>{t("home.footTrafficBenefit")}</p>
                      <p>{t("home.timeSavingBenefit")}</p>
                      <p>{t("home.visibilityWithoutEffort")}</p>
                      <p>{t("home.avoidPromotions")}</p>
                    </div>
                  </div>
                </div>
                {/* <div className={styles.card}>
                  <div className={styles.cardImage}>
                    <img src="Images/Group3.png" alt="" />
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.cardContentHeder}>
                      <p>Increase Visitors</p>
                    </div>
                    <div className={styles.cardContentDetails}>
                      <p>With your place listed, it’s easy</p>
                      <p>for users to find and visit your</p>
                      <p>spot for their meetups.</p>
                    </div>
                  </div>
                </div> */}

                <div className={styles.card}>
                  <div className={styles.cardImage}>
                    <img src="Images/Group4.png" alt="" />
                  </div>
                  <div className={styles.cardContent}>
                    <div
                      className={`${styles.cardContentHeder} ${styles.headerWidthTwo}`}
                    >
                      <p>{t("home.promoteOffersHeader")}</p>
                    </div>
                    <div className={styles.cardContentDetails}>
                      <p>{t("home.shareEvents")}</p>
                      <p>{t("home.directCommunication")}</p>
                      <p>{t("home.viaNotifications")}</p>
                      <p>{t("home.viaInAppAds")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.cardLayout}>
              <div className={styles.cardRow}>
                {/* Card 1: Increase Visitors */}
                <div className={styles.card}>
                  <div className={styles.cardImage}>
                    <img src="Images/Group3.png" alt="" />
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.cardContentHeder}>
                      <p>{t("home.increaseVisitorsHeader")}</p>
                    </div>
                    <div className={styles.cardContentDetails}>
                      <p>{t("home.increaseVisitorsLine1")}</p>
                      <p>{t("home.increaseVisitorsLine2")}</p>
                      <p>{t("home.increaseVisitorsLine3")}</p>
                    </div>
                  </div>
                </div>

                {/* Card 2: Boost Loyalty */}
                <div className={styles.card}>
                  <div className={styles.cardImage}>
                    <img src="Images/Group5.png" alt="" />
                  </div>
                  <div className={styles.cardContent}>
                    <div
                      className={`${styles.cardContentHeder} ${styles.headerWidthTwo}`}
                    >
                      <p>{t("home.boostLoyaltyHeader")}</p>
                    </div>
                    <div className={styles.cardContentDetails}>
                      <p>{t("home.boostLoyaltyLine1")}</p>
                      <p>{t("home.boostLoyaltyLine2")}</p>
                      <p>{t("home.boostLoyaltyLine3")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.boxContainer}>
          <div className={styles.boxLeft}>
            <div className={styles.boxHeder}>
              <p>{t("home.readyToGrow")}</p>
            </div>
            <div className={styles.boxContent}>
              <p style={{ lineHeight: "1.5rem" }}>{t("home.joinMessage")}</p>
              <img src="Images/Subtract.png" alt="" />
            </div>
            <button onClick={handlePartnerClick}>{t("home.partnerNow")}</button>
          </div>
          <div className={styles.boxRight}>
            <img src="Images/boxRightImg.svg" alt="" />
          </div>
        </div>

        <div className={styles.boxContainer1}>
          <h1>{t("home.readyToGrow")}</h1>
          <img src="Images/boxRightImg.svg" alt="" />
          <p style={{ lineHeight: "1.5rem" }}>{t("home.joinMessage")}</p>

          <button onClick={handlePartnerClick}>{t("home.partnerNow")}</button>
        </div>

        <div className={styles.adsContainer}>
          <img className={styles.image} src={imageSource} alt="missing" />
          <div className={styles.mobileAdsBanner}>
            <img src={imageSource} alt="missing" />
          </div>
          <div className={styles.mobileAdsContent}>
            <div className={styles.mobileAdsHeader}>
              <p>{t("home.notPartnerYet")}</p>
            </div>
            <div className={styles.mobileAdsDetails}>
              <p>{t("home.tryAds")}</p>
            </div>
            <button onClick={handleAdsClick}>{t("home.startWithAds")}</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
