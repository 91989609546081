import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navpages from "./components/Navpages";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();

  const lang = navigator.language;

  useEffect(() => {
    lang && i18n.changeLanguage(lang.split("-")[0]);
  }, [lang]);

  return (
    <>
      <Navpages />
    </>
  );
}

export default App;
