import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import style from "./OurGoalDetails.module.css";

const OurGoalDetails = () => {
  const { t } = useTranslation(); // Get the translation function

  return (
    <div className={style.main}>
      <div className={style.goalText}>
        <p>
          {t("goal.text")} {/* Use translation for goal text */}
        </p>
      </div>

      <div className={style.goalImage}>
        <div className={style.goalTitle}>{t("goal.title")}</div>{" "}
        {/* Use translation for goal title */}
        <img src="Images/OurGoal.png" alt={t("goal.title")} />{" "}
        {/* Use translation for alt text */}
      </div>
    </div>
  );
};

export default OurGoalDetails;
