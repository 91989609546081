import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import styles from "./aboutMeSection.module.css";

const AboutMeSection = () => {
  const { t } = useTranslation(); // Access translation function
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480); // Check if screen width is <= 480px
    };

    handleResize(); // Check on component load
    window.addEventListener("resize", handleResize); // Listen for screen resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  return (
    <div className={styles.Container}>
      <div className={styles.main}>
        <h1 className={styles.title}>{t("about_me_section.title")}</h1>

        <p className={styles.detail}>{t("about_me_section.description_1")}</p>
        <p className={styles.detail} style={{ marginTop: "1.2rem" }}>
          {t("about_me_section.description_2")}
        </p>
        <p className={styles.detail}>{t("about_me_section.description_3")}</p>
      </div>
      <div>{/* Additional content goes here */}</div>
    </div>
  );
};

export default AboutMeSection;
