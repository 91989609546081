import React from 'react';
import styles from './karimPage.module.css';
const KarimPage = () => {



    const handleRedirect = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isApple = /iphone|ipad|ipod|mac/.test(userAgent);
        const isWindows = /windows/.test(userAgent);

        if (isApple) {
            window.location.href = 'https://apps.apple.com'; // Replace with your App Store link
        } else if (isWindows) {
            window.location.href = 'https://play.google.com/store'; // Replace with your Play Store link
        } else {
            alert('Unsupported platform.'); // Optional fallback
        }
    };

    return (
        <div className={styles.aboutContainer}>
            {/* Header */}
            <div className={styles.aboutHeader}>
                {/* <h2>A couple of words from Karim Yamani, NETME Founder and CEO</h2> */}
                <h2>Behind the Story of NETME</h2>
            </div>

            {/* Profile Image */}
            {/* <div className={styles.profileImage}>
                <img src="Images/Karim.png" alt="Founder and CEO" />
            </div> */}
            {/* <img className={styles.memberPhoto} src="Images/TeamMemeberMen1.png" alt='none' /> */}

            {/* Quote Section */}
            <div className={styles.quoteSection}>
                <p>
                    NETME started with a simple idea: to bring people together in the real world. In today’s digital age, while we’re more connected than ever online, authentic, face-to-face connections are harder to find.
                    <br /><br />
                    The journey began when Karim came up with the idea to create a platform that helps people meet in person, using digital tools in a more meaningful way. Along the way, Alisa joined the team, bringing her vision and expertise to shape and elevate the project. Together, they’ve focused on making NETME a platform that goes beyond digital interactions — one that fosters real connections.
                    <br /><br />
                    As we prepare to launch our new app in December 2024, we’re excited to see how it will transform the way people connect. The journey is just beginning, and we’re excited to build this community with a shared focus on meaningful, in-person interactions.
                    <br /><br />
                    We’re just getting started — and we’re thrilled to have you with us on this journey.
                    <br /><br />
                    Karim & Alisa <br />
                    {/* Best of wishes, <br />
                    K.Y.” */}
                </p>
            </div>

            {/* Download Button */}
            <div className={styles.downloadButton}>
                <button onClick={() => handleRedirect()}>Download NETME</button>
            </div>
        </div>
    )
}

export default KarimPage