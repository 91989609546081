import React, { useEffect } from "react";
import styles from "./termsConditions.module.css";
import Title from "../../components/common/title/Title";
import Description from "../../components/common/description/Description";
import UnorderList from "../../components/common/unorderList/UnorderList";
import { Helmet } from "react-helmet-async";
const TermsConditions = () => {
  const group1 = [
    "These Terms and Conditions of Use (TCU) apply to the services offered on the website www.netme.eu and the mobile application.",
    "The service is provided by NETME UG (a limited liability company under the laws of Germany), registered on the Commercial Register of the Handelsregister München under the number HRB 238 228. The TCU define the rights and obligations of the parties in connection with the use of the mobile application. They form the basis of a contractual relationship between the member and the company NETME UG.",
    "These TCU define the main rules for using the mobile application. They are supplemented by additional policies, the Privacy Policy and the Cookie Policy, with the aim of providing the member with more detailed instructions for using the mobile application. In the event of a conflict between the different policies that renders the rules inaccessible, unintelligible and/or lacking in predictability, these TCU shall take precedence over the Privacy Policy and the Cookie Policy.",
    "By registering on NETME, e.g. on https://www.netme.eu (hereinafter referred to as “Website”) and all domains referring to it as well as in the mobile apps for iOS and Android devices (hereinafter referred to as “Mobile Apps”), the User agrees to the terms and conditions below and license agreement. Should the user not agree to the terms and conditions, registration must be waived.",
    "In the event that one of the stipulations in these TCU is declared invalid or inapplicable by a law, regulation or court judgement issued by a competent court, the other stipulations shall remain valid and in force, unless these TCU are thus undermined or such a situation would engender significant imbalance between the obligations of the parties.",
  ];

  const group2 = [
    {
      title: "Application:",
      des: "refers to the NETME application, available in the Apple App Store.:",
    },

    {
      title: "TCU:",
      des: "These Terms and Conditions of Use.",
    },

    {
      title: "Account:",
      des: "The Account is the Member’s personal area in the Application, which can be accessed by registering and logging into the Application. It enables Members to access the Services. Terms and Conditions of Use indicates this contract.",
    },

    {
      title: "Connection:",
      des: "When two Members accepted one another.",
    },
  ];

  const group3 = "A Premium account enables a Member to:";

  const group4 = [
    "Become a verified user",
    "Change their current location",
    "Hide their ‘last seen’ status",
    "Get their messages highlighted",
    "Decide to discover only other Verified users",
    "Receive unlimited Invitations",
  ];

  const group5 =
    "Action represented by an envelope. Sending an Invitation is an action that incurs a charge, enabling a Member to send an Invitation to another Member. Invitations for non premium members are limited and can be sent free of charge on a daily basis (2 per day).";
  const group6 =
    "An action represented by a text bubble, intended to send a request to another Member to start a conversation with each other. This action is visible, but only available if the other member accepted the request.";
  const group7 = [
    "These Terms and Conditions of Use shall be governed, interpreted and applied pursuant to German law, without prejudice to the protection provided to members by the mandatory provisions set out in German law. If the meaning of any provision or term within these TCU is contested, the member may contact NETME by post addressed to Stefan-George-Ring 55, 81929 Munich, or directly by email to hello@netme.eu",
    "In the event of a dispute, the member shall contact NETME in the first instance to settle the matter amicably. Any dispute that cannot be settled amicably shall be submitted to the competent District Court I of Munich without prejudice to the mandatory provisions set out in German law as regards the competence of the courts.",
  ];
  const group8 =
    "NETME may amend the TCU at any time. Members shall be informed of the nature of these amendments as soon as they are uploaded to the Site. Members shall be informed of the new Terms and Conditions of Use via the Application and/or in an email sent to the email address provided by them, and the new Terms and Conditions shall apply immediately.";
  const group9 =
    "It is the responsibility of the individual member to read the TCU carefully when they are updated and to read and understand any changes brought about by such an update. If a member should disagree with the amendments to the TCU, they shall have the right to cease using the Services and delete their Account pursuant to Article 10.";
  const group10 = [
    "The amendments shall apply immediately to members who sign up after they are uploaded onto the site, given that they will have accepted them expressly when becoming a member",
    "For members who signed up before the amendments were uploaded onto the site, the amendments shall be considered accepted unconditionally by said members if they continue to use the application’s services",
  ];
  const group11 =
    "The Application can be downloaded free of charge from the Apple App Store for iPhones operating iOS version 9.0 or later and also from the site. If the necessary conditions for membership have been met, NETME shall allocate the member a personal account containing:";
  const group12 = [
    "A profile that can be modified;",
    "A chat area;",
    "A match history.",
  ];
  const group13 = [
    "Any person who wishes to register to use the Services must be aged 18 or over, have read and accepted these Terms and Conditions of Use, the Cookie Policy and the Privacy Policy.",
    "If a Member signs up via Facebook Connect, NETME is granted permission to use their Facebook profile picture, first name, current city and age as ascertained from their date of birth to create a ‘profile’. The Member must therefore confirm that this information is correct on Facebook before registering for the Services.",
    "When registering, Members accept these TCU and the Privacy Policy and commit to complying with their obligations as stated in Article 7 of these TCU.",
  ];
  const group14 =
    "The Application gives NETME Members an intimate and private setting within which to contact other Members selected in line with compatibility criteria (geographical proximity and the search criteria included in Members’ profiles) with the aim of establishing virtual matches or setting up real meetings. Each Member is free to talk to and develop relationships with other Members in compliance with these TCU.";
  const group15 =
    "Some Services provided by NETME are available free of charge in the Application (aside from any internet and telecommunication charges) for Members who commit to complying with these TCU, including:";
  const group16 = [
    "2 daily invitation requests with another member. The invited member will, in turn, have the first member suggested to them. Members can then decide whether they like one another;",
    "The ability to send 2 ‘chat request’ to other NETME member profiles per day;",
    "The ability to send unlimited messages when a Connection is established between two Members;",
    "Unlimited and confidential views of the profiles of suggested Members, except when one party has blocked the other.",
    "An unlimited number of ‘rejects’ for invitations or chat requests.",
    "The ability to confidentially report the behaviour of any Member that contravenes these TCU or the applicable law;",
    "The ability to receive notifications;",
    "The ability to complete their profile, in compliance with these TCU.",
  ];

  const group17 = [
    "Obtain a verification badge on their profile so that they can increase their interaction with other Members",
    "Change their location, so they can find other Members in different areas, city or Country;",
    "Hide their ‘last seen’ status",
    "Get their chat request highlighted",
    "Get their invitations highlighted",
    "Get their messages highlighted",
    "Decide to discover only other Verified users",
    "Receive unlimited Invitations & Chat requests",
  ];

  const group18 =
    "Premium Memberships can be purchased in 4 different monthly packages(1, 3, 6 or 12 Months). Any purchased memberships is auto-renewed at the end of each package, on the condition of compliance with these TCU. However, in the event of the early termination of this contract due to non-compliance with these TCU, NETME reserves the right to close the Member’s Account without refunding the Premium Membership in the Member’s Account at that time.";
  const group19 =
    "Memberships cannot be purchased on an individual basis. They are available in the form of monthly Memberships containing several months packages, and at the prices indicated in the Apple App Store. Generally, all charges for purchases are nonrefundable, and there are no refunds or credits for partially used periods.";
  const group20 =
    "Memberships can be purchased securely through the Apple App Store using their respective payment methods. The Apple App Store are solely responsible for their payment methods and processes. NETME shall not intervene in these methods and processes pursuant to the conditions imposed by these platforms. Any refund request or request related to a payment must be addressed to the Apple department concerned.";
  const group21 = [
    "When registering for the Site or the Application, the user is required to provide personal information pursuant to Article 4.",
    "This information is subject to automated processing by NETME.",
    "When validating their registration for the Application, the user must read and accept NETME’s Privacy Policy.",
    "NETME uses cookies to operate and improve the Site and to improve the Services that it offers. By continuing to browse the Site, Members give their consent to the use of cookies.",
    "Pursuant to Articles 38 et seq. of Law No 78-17 of 6 January 1978 amended by Law No 2004-801 of 6 August 2004 on Computing, Data Files and Civil Liberties, any person may access and, if necessary, correct or remove their personal data by contacting NETME, whose contact details are provided in Article 2 of these TCU. Any person may also, for legitimate reasons, object to their personal data being processed.",
  ];
  const group22 = [
    "Being honest and sincere in their statements;",
    "Respecting the laws and regulations in force and not contravening public order;",
    "Respecting intellectual and industrial property rights;",
    "Not uploading photos of a third party;",
    "Not committing offences of defamation, slander or provocation, particularly of a racial or discriminatory nature, and not defending war crimes or crimes against humanity;",
    "Behaving with morality and decorum and, in particular, not circulating pornographic or exhibitionist content;",
    "Keeping secret any passwords and other confidential codes that could enable a third party or other Members to access the Services of their Account;",
    "Not using software, applications, interfaces or the assistance of a third party to interfere with contact between other Members of the Application (given that the – Application’s credibility depends wholly on exchanges being real);",
    "Respecting the confidentiality of other Members’ communications and their privacy;",
    "Not circulating or divulging the content of correspondence and Messages send directly to them in connection with the Services provided to them;",
    "Refraining from making any proposition or suggestion to another Member against their expressed wishes;",
    "Remaining polite and acting decently towards other Members;",
    "Not jeopardising the Services, Site or Application;",
    "Not offering, soliciting or promoting chargeable goods or services or financial compensation. Prostitution is formally prohibited on the Site and Application;",
    "Not diverting Members of the Application to another site or application or a competitor’s service;",
    "Refraining from any commercial use of, or advertisement using, the Site and Application;",
    "Not organising demonstrations or mass meetings of their members through the Site, Application or Services provided to them.",
  ];

  const group23 = [
    "Responsibilities related to compliance with its obligations",

    "NETME moderates the content published by Members when they register, in particular photos published by Members and the description added to a Member’s profile. However, the Member has sole responsibility for their own actions and the information they publish through the Application. If NETME is held liable for a Member’s failure to comply with their obligations, the Member shall guarantee NETME against any conviction handed down against it whose basis originates from a violation of the law or these TCU by the Member.",

    "Members may report prohibited content that violates applicable law or these TCU using the moderation tools provided by the Application in the menu found on each chat screen under the heading ‘Report’.",

    "Responsibilities related to publishing photos",

    "Any photograph that is pornographic or exhibitionist in nature or violates modesty or is generally considered immoral is formally prohibited. NETME reserves the right to delete any photograph that does not comply with the conditions above. Publishing any such photograph constitutes a serious offence which could lead to the Member’s Account being suspended and/or terminated, without prejudice to any other sanctions that may be imposed by the justice system.",

    "Responsibilities related to real meetings",

    "NETME provides warnings to Members who wish to meet another Member in real life. Members are wholly responsible for verifying the identity of the people with whom they arrange any such meeting. Such ‘real’ meetings take place independently of NETME, and are the sole responsibility of the Members involved. NETME cannot be held liable for any actions or problems of any nature whatsoever committed or provoked by Members and/or former Members during events that take place following use of the Application. In this regard, the NETME messaging system enables users to decide which Members are authorised to view their profiles and communicate with them, with the aim of giving Members peace of mind and preserving their security. It is highly recommended that Members do not reveal personal information to other Members, even to those authorised to exchange Messages with them, that does not appear on their NETME profile, such as phone numbers, addresses or surnames. NETME strongly recommends that, for security reasons, Members only arrange real meetings in very busy public places or that they inform a family member or friend of the meeting, and only reveal their contact details after a reasonable period of time.",

    "Responsibilities related to a Member’s equipment",

    "Members are solely responsible for paying for the equipment (hardware or software) required to access the Services, and all other related costs, including telecommunication charges. Members are solely responsible for their own equipment used in connection with the Service.",
  ];

  const group24 = [
    "NETME commits to providing the infrastructure and technical means required to provide a highquality Service to Members equipped with an iPhone handset running on the operating system iOS 9.0 or later. NETME cannot be held responsible for any operational faults in a Member’s handset or any compatibility issues between the Application and the handset. All possible measures are taken to minimise the risk of fraudulent access to NETME systems. In this regard, NETME has put in place a firewall (located between the internet network and the company’s private network to increase the company’s security by filtering traffic originating from or going to the internet).",

    "Nevertheless, it is impossible to fully guarantee the security of a network. NETME is not responsible for any breakdowns, interruptions or malfunctions in the services provided by Members’ internet access providers, nor for any reason outside of NETME’s control that could interrupt or impair access to NETME Services. Members must ensure that they have a secure email address. Despite, or in the absence of, these precautions, a Member’s Account may be susceptible to fraud.",

    "NETME also wishes to warn Members of the exceptional risk of receiving unwanted Messages or Messages that purport to have been sent by NETME. Members must be aware of the risk and vigilant, and warn NETME by letter or by email sent to the address provided in Article 2 of this document should such a situation arise.",
  ];

  const group25 = [
    "NETME and its partners cannot be held responsible for any illegal actions or actions subject to criminal sanctions committed by its Members, including but not limited to:",

    "Criminal behaviour",

    "NETME is not liable in cases of fraud, identity theft, prostitution, violation of the intellectual property of third parties or other criminal offences committed by its Members through the Site, the Application and the Services provided to them.",

    "Litigious content",

    "NETME reserves the right to save all litigious content, in particular in order to make it available to the competent authorities.",

    "Real meetings",

    "NETME is not responsible for real meetings organised by Members. Members who wish to organise such meetings are reminded that they do so at their own risk as indicated in Article 7 of these TCU, as NETME is unable to guarantee the truthfulness of information provided by its Members.",

    "Mass demonstrations",

    "NETME prohibits Members or third parties from organising demonstrations or mass meetings of their members through the Site and/or the Application made available to Members. NETME cannot be held responsible for any disturbance to public order or damages caused by Members or third parties participating in such meetings.",
  ];

  const group26 =
    "The ‘NETME’ trademark is the exclusive property of NETME. NETME is the holder of all of the associated rights. The trademark was filed with the German National Institute of Industrial Property under the number 302017237503. Any reproduction and/or use and/or partial or total affixing and/or modification and/or removal of the trademark and the associated rights such as, for illustrative purposes, but not limited to, the slogan and logo featured in the Application, by any means whatsoever, in any form or material whatsoever and through whatever medium whatsoever, is strictly forbidden without the prior written express authorisation of NETME and shall be subject to legal proceedings. Members should not use meta tags or any other hidden text containing the ‘NETME’ name or trademark without the express written agreement of NETME. The trademark is protected by Articles 9, 42 and 45 seq. of the Germany Intellectual Property Code and by international intellectual property law.";

  const group27 = [
    "NETME holds all of the intellectual property rights related to the Application and the Site. In the course of ordinary use of the Site or the Application and for this purpose alone, NETME grants Members the right to use the work featured in the Application and on the Site, such as, but not limited to texts, pagination, presentations, layouts, photographs, images, drawings, graphics, buttons, videos, sounds and data (‘Work’).",

    "The licence to use the Work granted by NETME to Members is non-exclusive and non-transferable and is reserved for private, personal and non-commercial use worldwide in connection with and for the duration of registration for the Services.",

    "It is prohibited to sell, rent, loan, distribute or sublicense all or part of a Work or to transfer any rights to all or part of this Work.",

    "It is prohibited to copy, modify or alter in any other manner a Work, in full or in part, to create works derived from a Work or to integrate all or part of a Work into other works, whatever form they may take.",

    "Works are protected by virtue of Articles 9, 42 and 45 seq. of the German Intellectual Property Code and international intellectual property law.",
  ];

  const group28 = [
    " The following conditions shall apply to all of NETME’s software (source codes, updates and developments, preparatory design material and related documentation) used in connection with the Services (the Software). All Software is the property of NETME, which holds all of the intellectual property rights and related usage rights. The Software is protected by Article 9, 42 and 45 of the German Intellectual Property Code and by international intellectual property law. In the course of ordinary use of the Site or the Application and for this purpose alone, NETME grants its Members the right to temporarily reproduce the Software. This reproduction right includes the right to load, display and execute the Software. Any rights not expressly granted to Members are held by NETME. The usage right granted by NETME to its Members is non-exclusive and non-transferable and is reserved for private and personal use worldwide in connection with and for the duration of registration for the Services.",

    "It is prohibited to sell, rent, loan, distribute or sublicense all or part of a piece of Software or to transfer any rights to all or part of this Software.",

    "It is prohibited to integrate all or part of a piece of Software into other programs, to compile all or part of the Software with another program, to transfer all or part of a piece of Software for use with another service or to copy, modify or alter in another manner a piece of Software in full or in part, to create works derived from the Software or to reverse engineer or decompile a piece of Software in full or in part, except within the limits authorised by the law. In order to keep the Software up to date, NETME may offer automatic or manual updates at any time and without prior notice.",
  ];

  const group29 = [
    "The database related to the Application and the Site is the exclusive property of NETME, which holds all of the associated intellectual property rights.",

    "It is prohibited to extract, by means of a permanent or temporary transfer, all or a qualitatively or quantitatively significant part of the content of the Application or Site onto another medium, by any means and in any form whatsoever.",

    "It is also prohibited to reuse all or a qualitatively or quantitatively significant part of the content of the Application or Site by placing it at the disposal of the public, whatever form it may take.",

    "Finally, it is prohibited to repeatedly and systematically extract or reuse qualitatively or quantitatively insignificant parts of the Application or Site if such operations manifestly exceed the normal conditions of use of the Application or the Site.",
  ];

  const group30 = [
    "It is formally prohibited to design a product similar to the Application or Site or to create an Application or Site derived therefrom, and more generally to commit acts of free riding, imitation, disparagement or any other act that constitutes unfair competition. If applicable, NETME reserves the right to serve summons on a Member with the aim of obtaining full reparation for damages caused by the said Member.",

    "Members shall risk facing sanctions if they succeed in or attempt to grant licences or sublicensees, sell, resell, transfer, yield, distribute or operate commercially in another manner the Services, Application or the Site in any way whatsoever or make them available to a third party.",
  ];

  const group31 = [
    "Each Member grants a usage license to NETME for the intellectual property rights attached to the content provided by said Member in connection with the use of the Services. This license shall be operated by NETME, within the limitations imposed by image rights and Members’ privacy, without reducing the authenticity of the Member’s profile, and remaining in conformity with this profile. This licence includes in particular the right for NETME to reproduce, represent and modify such content with the aim of complying with the graphic design of the Services and to make such content compatible with the technical performance or media formats concerned. These rights are granted worldwide and for the duration of the enforcement of the Terms and Conditions of Use between the Member and NETME.",

    "The Member remains the owner of the content and the personal data they provide. The Member guarantees NETME against any legal action, redress or conviction handed down against it originating from a violation of a third party’s intellectual property by the Member. The Member guarantees NETME against any claims or damage that could result therefrom.",
  ];

  const group32 = [
    "Uninstalling an Account: The Member may deactivate their Account at any time without notice through the Application if they no longer wish to be visible.",

    "Deleting an Account: The Member may decide to delete their Account definitively by sending an email or letter to NETME, whose postal address and email address appear in Article 2 of this document, at any time and without notice. As a result, the Member’s profile shall be deleted permanently. If the Member then wishes to resume use of the Application, they shall be required to register under the same conditions as a user signing up for the first time.",

    "When an Account is deleted:",
  ];

  const group33 = [
    "The Member’s profile shall be made invisible to other Members;",
    "The data identifying the Member shall be retained for a period of one year from this date;",
    "The Member shall not be able to reactivate their old Account.",
  ];

  const group34 = [
    "In the event that a Member violates the Terms and Conditions of Use, their Account may be suspended temporarily at any time and then fully terminated by NETME.",

    "NETME shall be free, at any time and in the event of non-compliance with these Terms and Conditions of Use by a Member, to suspend access to said Member’s Account on the Site and Application. NETME shall inform the Member whose Account has been suspended of this decision by means of a notification in the Application and/or by email. The Member is invited to contact NETME’s customer service department by email to provide an explanation for the accusations levelled against them. If the Member does not respond and/or if the explanations provided do not justify the violation, NETME shall notify the Member that their Account has been terminated through the Application and/or by email, and inform the Member of the period during which they shall be unable to re-register for the Application.",

    "When an Account is terminated:",
  ];

  const group35 = [
    "The Member’s profile shall be made invisible to other Members;",
    "The data identifying the Member shall be retained for a period of one year from this date;",
    "The Member shall not be able to reopen an Account;",
  ];

  const group36 = [
    "A mutual ‘like’ received from another Member;",
    "A Message or Invitation received from another Member;",
    "An instant match;",
  ];

  const group37 = [
    "The email address to which notifications are sent is that provided by the Member when they registered. The settings for notifications can be changed in the Member’s Account, and notifications can be deactivated at any time.",

    "Newsletter: the Member registers for the Application, they consent to receiving a newsletter from NETME. They have the right to change their mind at any time by clicking on the unsubscribe link at the bottom of each email received from NETME.",
  ];
  useEffect(() => {
    window.scrollTo({ top: 0 }); // Smooth scroll to top
  }, []);
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - NETME</title>
        <meta
          name="description"
          content="NETME: Your Passport to Offline Connections"
        />
      </Helmet>

      <div className={styles.container}>
        <Title title="Terms and Conditions of Use – NETME" className="center" />
        <Title title="Preamble" />
        {group1.map((item) => (
          <Description des={item} />
        ))}
        <Title title="Article 1 – Definitions" />
        <Description des="In these TCU, the words and expressions below shall have the following meanings:" />
        {group2.map((item) => (
          <>
            <Title title={item.title} padding="30px" />
            <Description des={item.des} padding="0px" />
          </>
        ))}
        <Title title="Premium:" />
        <Description des={group3} padding="0px" />
        <UnorderList list={group4} />
        <Title title="Send a Invitation:" padding="30px" />
        <Description des={group5} padding="0px" />
        <Title title="Send a chat request:" padding="30px" />
        <Description des={group6} padding="0px" />
        <Title title="Member(s):" padding="30px" />
        <Description
          des="A person (or people) registered in the Application."
          padding="0px"
        />
        <Title title="Facebook Connect:" padding="30px" />
        <Description
          des="A function that enables Members to use their Facebook account to log into the Application, thus simplifying the registration procedure."
          padding="0px"
        />
        <Title title="NETME:" padding="30px" />
        <Description
          des="NETME UG registered on the Commercial Register of the Handelsregister München under the number HRB 238 228, the owner and sole operator of the Application and the ‘NETME’ trademark."
          padding="0px"
        />
        <Title title="Premium membership:" padding="30px" />
        <Description des="Memberships purchased by Members." padding="0px" />
        <Title title="Reject:" padding="30px" />
        <Description
          des="An action represented by a cross that expresses a Member’s lack of interest in another Member. This action is secret and no notification is sent. ‘Rejected’ profil es will no longer be able to contact the Member for a certain amount of time (21 days) unless they ‘Blocked’ the Member’s profile (undoable action)."
          padding="0px"
        />
        <Title title="Services:" padding="30px" />
        <Description
          des="All of the services, both chargeable and free, offered by NETME to help bring about virtual matches or real meetings between Members with a mutual interest in one another."
          padding="0px"
        />
        <Title title="Site:" padding="30px" />
        <Description des="The website www.NETME.eu" padding="0px" />

        <Title title="Article 2 – Applicable law and disputes" />
        <Description des={group7} />

        <Title title="Article 3 – Amendments to the Services and Terms and Conditions of Use" />
        <Description des={group8} />
        <UnorderList list={group10} />
        <Description des={group9} />

        <Title title="Article 4 – Conditions for accessing and registering for the Services" />
        <Description des={group11} />
        <UnorderList list={group12} />
        {group13.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Article 5 – Services and functions" />
        <Description des={group14} />

        <Title title="Free Services" />
        <Description des={group15} />
        <UnorderList list={group16} />

        <Title title="Chargeable Services" />
        <Description des="The chargeable Services offered through the Application can be accessed by Members by purchasing a Premium Membership." />
        <Description des="A Premium Membership enables Members to:" />
        <UnorderList list={group17} />
        <Description des={group18} />

        <Title title="Price" />
        <Description des={group19} />

        <Title title="Payment security" />
        <Description des={group20} />
        <Description des="Bank details are collected by Apple, beyond NETME’s control." />

        <Title title="Article 6 – NETME’s Privacy Policy" />
        {group21.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Article 7 – Members’ obligations and responsibilities" />
        <Title title="Obligations" />
        <Description des="When using the Services defined in Article 5, Members commit to:" />
        <UnorderList list={group22} />

        <Title title="Responsibilities" />
        {group23.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Article 8 – NETME’s obligations and responsibilities" />
        <Title title="Obligations" />

        {group24.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Responsibilities" />
        {group25.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Article 9 – Intellectual property" />
        <Title title="Trademark law" />
        <Description des={group26} />

        <Title title="Copyright" />
        {group27.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Software" />
        {group28.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Database use" />
        {group29.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Unfair competition" />
        {group30.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Content uploaded by Members" />
        {group31.map((item) => (
          <Description des={item} />
        ))}

        <Title title="Article 10 – Termination" />
        <Title title="Termination by the Member" />
        {group32.map((item) => (
          <Description des={item} />
        ))}
        <UnorderList list={group33} />

        <Title title="Termination of an Account by NETME in the case of a violation of the Terms and Conditions of Use" />
        {group34.map((item) => (
          <Description des={item} />
        ))}
        <UnorderList list={group35} />

        <Title title="Article 11 – Making contact" />
        <Title title="With Members" />
        <Description des="Sending mobile and email notifications: Members may receive notifications through the Application and/or by email in the case of the following events:" />
        <UnorderList list={group36} />
        {group37.map((item) => (
          <Description des={item} />
        ))}

        <Title title="With NETME" />
        <Description des="Members may contact NETME directly by post to the following address: Stefan-George-Ring 55 81829 Munich, Germany or by email to hello@netme.eu." />
      </div>
    </>
  );
};

export default TermsConditions;
