import React, { useEffect } from "react";
import styles from "./legalDisclosure.module.css";
import Title from "../../components/common/title/Title";
import Description from "../../components/common/description/Description";
import { Helmet } from "react-helmet-async";
const LegalDisclosure = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 }); // Smooth scroll to top
  }, []);

  const group1 = [
    "NETME UG (haftungsbeschränkt)",
    "Stefan-George-Ring 55",
    "81929 München",
    "Represented by: Karim Yamani",
    "Umsatzsteuer-ID: DE316539900",
    "Registered in the Commercial Register of the Munich District Court under the register number: HRB 238228",
  ];
  const group2 = ["Karim Yamani", "info@netme.eu"];
  const group3 =
    "We are not prepared or obliged to participate in any dispute resolution proceedings prior to a consumer arbitration board.";
  const group4 =
    "The contents of our pages were created with the greatest care. For the correctness, however, we cannot assume any liability for the completeness and topicality of the contents. As a service provider we are responsible according to § 7 Abs.1 TMG for own contents on these sides according to the general laws. According to §§ 8 to 10 TMG we are as a service provider however not obligated to monitor transmitted or stored third-party information or to update such information in accordance with to research circumstances that indicate illegal activity. Obligations to removal or blocking of the use of information under the general laws will remain unaffected by this. A liability in this regard is however only valid from the time of knowledge. a concrete violation of the law is possible. Upon becoming aware of corresponding we will remove these contents immediately if they violate the law.";
  const group5 =
    "Our offer contains links to external websites of third parties, on whose contents we do not have any influence. Therefore we can not assume any liability for these external contents. For the contents of the linked pages is always the respective provider or operator of pages responsible. The linked pages were at the time of linking up possible legal violations checked. Illegal content was not recognizable at the time of linking. However, a permanent content control of the linked pages is without concrete evidence of an infringement unreasonable. Upon becoming aware of violations, we will remove such links immediately.";
  const group6 =
    "The content and works on these pages created by the site operators are subject to German copyright law. The duplication, treatment, spreading and each kind of the use outside the limits of copyright law requires written consent. The duplication, processing, distribution and any kind of exploitation outside the limits of copyright require the writte consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. As far as the contents on this side were not created by the operator, the copyrights of third parties are considered. In particular contents of third parties are marked as such. If you become aware of any infringement of copyright, please inform us accordingly.Upon notification of violations, we will remove such content immediately.";
  return (
    <>
      <Helmet>
        <title>Legal Disclosure - NETME</title>
        <meta
          name="description"
          content="NETME: Your Passport to Offline Connections"
        />
      </Helmet>

      <div className={styles.container}>
        <Title title="Legal Disclosure" />
        {group1.map((item) => (
          <Description des={item} />
        ))}
        <Title title="Contact Information" />
        {group2.map((item) => (
          <Description des={item} />
        ))}
        <Title title="Dispute Settlement" />
        <Description des={group3} />
        <Title title="Liability for contents" />
        <Description des={group4} />
        <Title title="Liability for Links" />
        <Description des={group5} />
        <Title title="Copyright" />
        <Description des={group6} />
      </div>
    </>
  );
};

export default LegalDisclosure;
