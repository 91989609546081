import React from 'react'
import styles from './followUsSection.module.css';
const FollowUsSection = () => {
  return (
    <div className={styles.main}>
        <div className={styles.image}>
        <img src="Images/connect.png" alt="1" />
        </div>
        <p className={styles.title}>FOLLOW US @NETMEAPP</p>



    <div style={{ width: '50%', overflow: 'hidden', border: '0', display:'flex', justifyContent:'center',alignItems:'center'}}>
    <iframe 
    src="https://cdn.lightwidget.com/widgets/a0e5ff617bd15115a761812817c7d12c.html"
    scrolling="no"
    allowtransparency="true"
    className="lightwidget-widget"
    style={{width:"100%", border:'0', overflow:'hidden', height: '20rem', }}/>
   </div>
 
   </div>
  )
}

export default FollowUsSection

