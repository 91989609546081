import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FAQSection.module.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const FAQSection = () => {
  const { t } = useTranslation(); // hook to access translations

  const Data = [
    {
      cardTitle: t("faqSection.cards.0.cardTitle"),
      cardDetail: t("faqSection.cards.0.cardDetail"),
    },
    {
      cardTitle: t("faqSection.cards.1.cardTitle"),
      cardDetail: t("faqSection.cards.1.cardDetail"),
    },
    {
      cardTitle: t("faqSection.cards.2.cardTitle"),
      cardDetail: t("faqSection.cards.2.cardDetail"),
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animations should only happen once
    });
    AOS.refresh(); // Ensure animations are recalculated
  }, []);

  return (
    <div className={styles.Container}>
      <p className={styles.title}>{t("faqSection.faqTitle")}</p>
      <div style={{ display: "inline-block" }}>
        {Data.map((item, index) => (
          <div
            key={index}
            className={styles.card}
            data-aos="fade-up" // Add AOS animation
            data-aos-delay={index * 200} // Add delay to make them animate one after another
          >
            <p className={styles.cardTitle}>{item.cardTitle}</p>
            <p className={styles.cardDetail}>{item.cardDetail}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
