import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./about.module.css";
import TeamMember from "../../components/About/TeamMember/TeamMember";
import AboutStory from "../../components/About/Story/AboutStory";
import OurVission from "../../components/About/OurVision/OurVision";
import OurGoalDetails from "../../components/About/OurGoal/OurGoalDetails";
import { Helmet } from "react-helmet-async";

const AboutPage = () => {
  const { t } = useTranslation();

  const handleRedirect = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isApple = /iphone|ipad|ipod|mac/.test(userAgent);
    const isWindows = /windows/.test(userAgent);

    if (isApple) {
      window.location.href = "https://apps.apple.com"; // Replace with your App Store link
    } else if (isWindows) {
      window.location.href = "https://play.google.com/store"; // Replace with your Play Store link
    } else {
      alert(t("about_section.unsupportedPlatform")); // Optional fallback
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 }); // Smooth scroll to top
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("about_section.title")}</title>
        <meta
          name="description"
          content={t("about_section.about_section.metaDescription")}
        />
      </Helmet>
      <div className={styles.aboutContainer}>
        <h2 className={styles.aboutTitle}>{t("about_section.aboutTitle")}</h2>

        <div className={styles.teamMembers}>
          {t("about_section.teamMembers", { returnObjects: true }).map(
            (member, index) => (
              <TeamMember
                key={index}
                name={member.name}
                image={member.image}
                description={member.description}
              />
            )
          )}
        </div>

        <div
          className={styles.aboutStorySection}
          style={{ marginTop: "6rem", marginBottom: "6rem" }}
        >
          <AboutStory />
        </div>
        <div className={styles.aboutStorySection}>
          <OurVission />
        </div>

        <div className={styles.outGoalMargin}>
          <OurGoalDetails />
        </div>

        <div className={styles.aboutStorySection}>
          <div className={styles.startNetwork}>
            <p onClick={() => handleRedirect()}>
              {t("about_section.startNetworking")}
            </p>
          </div>
        </div>

        <div className={styles.aboutStorySection}>
          <div className={styles.letsNetmeImage}>
            <img
              src="Images/aboutBottumPictureWebp.webp"
              alt={t("about_section.aboutTitle")}
            />
          </div>
        </div>

        <div className={styles.footerMargin}>
          <span className={styles.footerText}>
            {t("about_section.footerText")}
          </span>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
