import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // Import Yup

import { useLocation, useNavigate } from "react-router-dom";
import styles from "./register.module.css";
import { message } from "antd";
import Payment from "./Payment";
import LegalRepresentative from "./LegalRepresentative";
import TermsCondition from "./TermsCondition";
import Thanking from "./Thanking";
import UploadFile from "../../components/UploadFile";
import LocationPicker from "../../components/Location/LocationPicker";
import Select from "react-select";
import BannerStyle from "./banner.module.css";
import LocationMapPicker from "../../components/Location/LocationMapPicker";
import { background, useDisclosure } from "@chakra-ui/react";
import LocationModal from "../../components/Location/components/LocationModal";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const dropdownOptions = [
  { value: "Restaurant", label: "Restaurant" },
  { value: "Bar", label: "Bar" },
  { value: "Cafe", label: "Cafe" },
];

const Register = () => {
  const { t } = useTranslation();
  const _location = useLocation();
  const [selectedOption, setSelectedOption] = useState("");
  const locationNameRef = useRef(null);
  const companyTaxNoRef = useRef(null);
  const compMobileRef = useRef(null);
  const compEmailRef = useRef(null);
  const cityRef = useRef(null);
  const fullNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const mobileRef = useRef(null);
  const jobPositionRef = useRef(null);
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "10px",
      height: "58px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "1rem",
      backgroundColor: "#f7fcff",
      fontWeight: "500",
      paddingLeft: "14px",
      backgroundImage: "url(../../../public/Images/dropdown.svg)",

      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "50px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      // width: "100%",
      margin: "0px",
      borderRadius: "1rem",
      // backgroundColor: "#e5e5e5",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      // height: "55px",
      // backgroundColor: "#e5e5e5",
      // backgroundColor: 'red',
      color: "black",
      cursor: "pointer",
      borderRadius: "2rem",
      borderBottom: "1px solid white",
      // textAlign: "center",
      fontFamily: "GalanoGrotesque-Medium",
      // fontSize: "1.2rem",
      // fontWeight: "500",
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
      "@media (max-width: 767px)": {
        fontSize: "4vw",
        height: "12vw",
      },
      ":last-child": {
        borderBottom: "none",
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default arrow and line
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const navigate = useNavigate();
  const [location, setLocation] = useState("form1");
  const [companyDetails, setCompanyDetails] = useState({
    legalDetails: {},
    businessDetails: {},
  });
  const [userData, setUserData] = useState({});
  const [fileUploaded, setFileUploaded] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [error, setError] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [address, setAddress] = useState(null);

  const validationSchema = Yup.object().shape({
    locationName: Yup.string().required("Location name is required"),
    category: Yup.string().required("Category is required"),
    companyTaxno: Yup.string().required("Company tax number is required"),
    compMobile: Yup.string()
      .matches(/^\d{7,15}$/, "Mobile number must be between 7 and 15 digits")
      .required("Company mobile number is required"),
    compEmail: Yup.string()
      .email("Invalid email format")
      .required("Business email is required"),
    phone: Yup.string().matches(/^\d{7,15}$/, "Phone number is invalid"),
    // address: Yup.string().required("Address is required"),

    // address: Yup.string()
    //   .required('Business location is required')
    // ,
    // photos: Yup.array()
    //   .min(1, "At least one image is required")
    //   .required("Upload at least one image"),
  });

  useEffect(() => {
    window.scrollTo({ top: 0 }); // Smooth scroll to top
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => setAddress(data.address));
    }
  }, [latitude, longitude]);

  // if (location === "form2") {
  //   return <Payment setLocation={setLocation} data={companyDetails} />;
  // }

  // if (location === "form3") {
  //   return <TermsCondition setLocation={setLocation} data={companyDetails} />;
  // }

  // if (
  //   location === "form4" ||
  //   new URLSearchParams(_location.search).get("step") === "4"
  // ) {
  //   return (
  //     <Thanking
  //       setLocation={setLocation}
  //       data={companyDetails?.companyDetails}
  //     />
  //   );
  // }
  if (location === "form2") {
    if (fileUploaded.length === 0) {
      setLocation("form1");
      message.error("Upload at least one picture");
      return null;
    }

    return (
      <LegalRepresentative setLocation={setLocation} data={companyDetails} />
    );
  }

  if (location === "form3") {
    return <Payment setLocation={setLocation} formData={companyDetails} />;
  }

  if (location === "form4") {
    return <TermsCondition setLocation={setLocation} data={companyDetails} />;
  }

  if (
    location === "form5" ||
    new URLSearchParams(_location.search).get("step") === "4"
  ) {
    return (
      <Thanking
        setLocation={setLocation}
        data={companyDetails?.companyDetails}
      />
    );
  }

  const handleSubmit = async (values) => {
    setUserData(values);

    if (address) {
      userAddress.house_number = address?.house_number;
      userAddress.postcode = address?.postcode;
      userAddress.street = address?.road;
    }

    console.log("userAddress...........=> ", userAddress);

    let data = {
      legalDetails: {
        email: values.email,
        fullName: values.fullName,
        lastName: values.lastName,
        jobPosition: values.jobPosition,
        mobile: values.mobile,
      },
      businessDetails: {
        name: values.locationName,
        location: {
          type: "Point",
          coordinates: [latitude, longitude],
        },
        address: userAddress?.address,
        addressObject: userAddress,
        category: selectedOption.value,
        city: address?.city,
        // postCode: address?.postcode ? address?.postcode : values.postCode,
        // houseNumber: address?.house_number
        //   ? address?.house_number
        //   : values.houseNumber,
        taxNumber: values.companyTaxno,
        businessEmail: values.compEmail,
        businessMobile: values.compMobile,
        businessTel: values.phone,
        photos: fileUploaded,
      },
    };

    setCompanyDetails(data);

    if (values.locationName === "") {
      message.error("Please enter business name");
      console.log("Location name is empty");
      locationNameRef.current.focus();
    } else if (!userAddress) {
      // message.error("Please enter address");
      console.log("Address is empty");
    } else if (selectedOption === "") {
      message.error("Please select category");
      console.log("Category is not selected");
    } else if (values.companyTaxno === "") {
      message.error("Please enter company tax no.");
      console.log("Company tax no. is empty");
      companyTaxNoRef.current.focus();
    } else if (values.compEmail === "") {
      message.error("Please enter business email");
      console.log("Business email is empty");
      compEmailRef.current.focus();
    } else if (values.compMobile === "") {
      message.error("Please enter company mobile no.");
      console.log("Company mobile no. is empty");
      compMobileRef.current.focus();
    } else if (!/^\d{7,15}$/.test(values.compMobile)) {
      message.error("Phone number should have between 7 and 15 digits");
      console.log("Invalid company mobile number");
    } else if (values.fullName === "") {
      message.error("Please enter full name");
      console.log("Full name is empty");
      fullNameRef.current.focus();
    } else if (values.lastName === "") {
      message.error("Please enter lastname");
      console.log("Last name is empty");
      lastNameRef.current.focus();
    } else if (!/^[A-Za-z]+$/.test(values.lastName)) {
      message.error("Last name should contain only alphabetic characters");
      console.log("Invalid last name");
      lastNameRef.current.focus();
    } else if (values.jobPosition === "") {
      message.error("Please enter job position");
      console.log("Job position is empty");
      jobPositionRef.current.focus();
    } else if (values.email === "") {
      // message.error("Please enter email");
      console.log("Email is empty");
      emailRef.current.focus();
    } else if (!validateEmail(values.email)) {
      message.error("Please enter a valid email");
      console.log("Invalid email format");
      emailRef.current.focus();
    } else if (values.mobile === "") {
      message.error("Please enter mobile no.");
      console.log("Mobile number is empty");
      mobileRef.current.focus();
    } else if (!/^\d{7,15}$/.test(values.mobile)) {
      message.error("Mobile number should have between 7 and 15 digits");
      console.log("Invalid mobile number");
      mobileRef.current.focus();
    } else if (!fileUploaded.length) {
      message.error("Upload at least one picture");
      console.log("Company mobile no. is empty");
    } else {
      const alreadyEmail = false; // await checkEmail(values.email)
      if (alreadyEmail) {
        message.error("Email already registered");
        console.log("Email is already registered");
      } else {
        // message.error("Success");
        setLocation("form2");
        console.log("Success");
      }
    }

    if (!userAddress) {
      setAddressError("Address required");
    }
    if (error) {
      setAddressError(null);
    }

    if (!error && !addressError && userAddress) {
      setError("");
      setAddressError("");
      setLocation("form2");
    }
  };

  console.log({ error, addressError, userAddress });
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <Helmet>
        <title>Partner Registration - NETME</title>
        <meta
          name="description"
          content="NETME: Your Passport to Offline Connections."
        />
      </Helmet>
      <div className={styles.container}>
        <div className={BannerStyle.banner}>
          <div className={BannerStyle.bannerTitle}>
            {t("register.bannerTitle")}
          </div>
          {/* <div className={BannerStyle.bannerTitle}>REGISTRATION FORM</div> */}
          <div className={BannerStyle.bannerSubTitle}>
            {" "}
            {t("register.bannerSubtitle")}
          </div>
        </div>
        {/* <div className={styles.form_pic_con}>
        <img src="Images/formOne.svg" alt="" />
      </div> */}

        <div className={styles.main_form}>
          <div className={styles.sub_form}>
            <Formik
              initialValues={{
                locationName: "",
                category: "",
                companyTaxno: "",
                mobile: "",
                compMobile: "",
                address: {},
                // city: "",
                email: "",
                compEmail: "",
                phone: "",
                ...userData,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, isValid, touched }) => (
                <Form>
                  <div className={styles.paging}>
                    <p className={styles.loc}>{t("register.location")}</p>
                    <p className={styles.count}>1 / 4</p>
                  </div>
                  <div className={styles.underLineBorder}>
                    <img src="Images/underline.svg" alt="" />
                  </div>

                  <div className={styles.location}>
                    <div className={styles.location_a}>
                      <label className={styles.label}>
                        {t("register.whatBusinessCalled")}
                        <sup>*</sup>
                      </label>
                      <br></br>
                      <Field
                        name="locationName"
                        type="text"
                        className={styles.form_field_name}
                        placeholder="Enter Name"
                        innerRef={locationNameRef}
                      ></Field>
                      <ErrorMessage
                        name="locationName"
                        component="div"
                        className={styles.error}
                      />
                      <br></br>

                      <label className={styles.label}>
                        {t("register.category")}
                        <sup>*</sup>
                      </label>
                      <br></br>
                      <div
                        style={{ marginTop: "0.5rem", marginBottom: "0.8rem" }}
                      >
                        <Select
                          options={dropdownOptions}
                          styles={customStyles}
                          value={selectedOption}
                          // onChange={handleOptionChange}
                          onChange={(selected) => {
                            setSelectedOption(selected); // update the selected option state
                            setFieldValue(
                              "category",
                              selected ? selected.value : ""
                            ); // update Formik state
                          }}
                          placeholder={t("register.selectCategory")}
                          components={{
                            // Add a custom dropdown indicator with your image
                            IndicatorSeparator: () => null, // Hide the separator
                            DropdownIndicator: () => (
                              <img
                                src="Images/dropdown4.svg"
                                alt="Custom Dropdown Indicator"
                                style={{
                                  width: "15px",
                                  height: "2vw",
                                  marginRight: "1vw",
                                }} // Adjust the dimensions as needed
                              />
                            ),
                          }}
                        />
                        {errors.category && touched.category && (
                          <div className={styles.error}>{errors.category}</div>
                        )}
                      </div>
                      <label className={styles.label}>
                        {t("register.companyTaxNo")}
                        <sup>*</sup>
                      </label>
                      <br></br>
                      <Field
                        name="companyTaxno"
                        type="text"
                        className={styles.form_field}
                        placeholder={t("register.placeholders.companyTaxno")}
                        innerRef={companyTaxNoRef}
                      ></Field>
                      <ErrorMessage
                        name="companyTaxno"
                        component="div"
                        className={styles.error}
                      />
                      <br></br>
                      <label className={styles.label}>
                        {t("register.mobileNumber")}
                        <sup>*</sup>
                      </label>
                      <br></br>
                      <Field
                        name="compMobile"
                        type="number"
                        className={styles.form_field}
                        placeholder={t("register.placeholders.mobileNumber")}
                        innerRef={mobileRef}
                      ></Field>

                      <ErrorMessage
                        name="compMobile"
                        component="div"
                        className={styles.error}
                      />
                      <br></br>
                    </div>

                    <div className={styles.location_b}>
                      <div className={styles.addressContainer}>
                        <div className={styles.addressParent}>
                          <label className={styles.label}>
                            {t("register.address")} <sup>*</sup>
                          </label>
                          <br></br>

                          <div className={styles.locationAddress}>
                            <LocationPicker
                              setLatitude={setLatitude}
                              setLongitude={setLongitude}
                              setUserAddress={setUserAddress}
                              userAddress={userAddress}
                              setError={setError}
                              error={error}
                              addressError={addressError}
                              setAddressError={setAddressError}
                            />
                            {/* {errors.address && touched.address && (
                              <div className={styles.errorMessage}>{errors.address}</div>
                            )} */}
                            <ErrorMessage
                              name="address"
                              component="div"
                              className={styles.error}
                            />
                          </div>
                        </div>
                      </div>

                      <label className={styles.label}>
                        {t("register.email")}
                        <sup>*</sup>
                      </label>
                      <br></br>
                      <Field
                        name="compEmail"
                        type="email"
                        className={styles.form_field}
                        placeholder={t("register.placeholders.email")}
                        innerRef={emailRef}
                      ></Field>
                      {/* {errors.compEmail && (
                        <div className={styles.error}>{errors.compEmail}</div>
                      )} */}

                      <ErrorMessage
                        name="compEmail"
                        component="div"
                        className={styles.error}
                      />
                      <br></br>
                      <label className={styles.label}>
                        {t("register.phoneNumber")}
                      </label>
                      <br></br>
                      <Field
                        name="phone"
                        type="number"
                        className={styles.form_field}
                        placeholder={t("register.placeholders.phoneNumber")}
                      ></Field>
                      {errors.phone && (
                        <div className={styles.error}>{errors.phone}</div>
                      )}
                      <br></br>
                      <label className={styles.label}>
                        {t("register.uploadImage")}
                      </label>
                      <br></br>
                      {/* <div className={styles.upload}>
                  </div> */}
                      <UploadFile setFileUploaded={setFileUploaded} />
                      <br></br>
                    </div>
                  </div>

                  {/* <div className={styles.formMargin}>
                <p className={styles.im}>Images</p>
                <div className={styles.underLineBorderThree}>
                  <img
                    src="Images/underline.svg"
                    alt=""
                  // style={{ height: "0.24rem" }}
                  />
                </div>
              </div>

              <div className={styles.upload}>
                <UploadFile setFileUploaded={setFileUploaded} />
              </div> */}
                  <div className={styles.form_btn}>
                    <div></div>
                    <button
                      type="submit"
                      id={location === "form2"}
                      // onClick={() => handleSubmit(userData)}
                      disabled={fileUploaded.length === 0}
                    >
                      {t("register.nextButton")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
