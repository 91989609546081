import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // Import the hook
import "./workSection.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Search from "../../../assets/icons/search.svg";
import Location from "../../../assets/icons/location.svg";
import Message from "../../../assets/icons/message.svg";
import Group from "../../../assets/icons/group.svg";
import Button from "../../Button";

const Test = () => {
  const { t } = useTranslation(); // Hook to fetch translation
  const [iconSize, setIconSize] = useState("");
  const [mainHeight, setMainHeight] = useState("");

  const handleRedirect = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isApple = /iphone|ipad|ipod|mac/.test(userAgent);
    const isWindows = /windows/.test(userAgent);

    if (isApple) {
      window.location.href = "https://apps.apple.com"; // Replace with your App Store link
    } else if (isWindows) {
      window.location.href = "https://play.google.com/store"; // Replace with your Play Store link
    } else {
      alert("Unsupported platform."); // Optional fallback
    }
  };

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth < 320) {
        setMainHeight({ height: "2560px" });
      } else if (window.innerWidth < 500) {
        setMainHeight({ height: "2150px" });
      } else if (window.innerWidth < 1169) {
        setIconSize({ marginLeft: "15px" });
        setMainHeight({ height: "1850px" });
      } else {
        setIconSize({ marginLeft: "-115px" });
        setMainHeight({ height: "2230px" });
      }
    };

    updateSize(); // Run on initial render
    window.addEventListener("resize", updateSize); // Add resize listener
    return () => window.removeEventListener("resize", updateSize); // Cleanup listener
  }, []);

  return (
    <>
      <div
        style={{ ...mainHeight, backgroundColor: "#8CC9E936" }}
        className="box1"
      >
        <p className="title">{t("work_section.how_netme_works")}</p>

        <VerticalTimeline className="custom-timeline">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "transparent", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  transparent" }}
            date={t("work_section.discover")}
            iconStyle={{ background: "#333333", color: "black" }}
          >
            <div className="box1">
              <div className="internalBox">
                <span className="circles1">
                  <p className="circlesText">
                    {t("work_section.discover_desc")}
                  </p>
                  <img className="svg" src={Search} alt="none" />
                </span>
                <img className="phone" src="Images/phone1.svg" alt="none" />
              </div>
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "transparent", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  transparent" }}
            date={t("work_section.choose_vibe")}
            iconStyle={{ background: "#333333", color: "black" }}
          >
            <div className="box1">
              <div className="internalBox">
                <img
                  className="phone2"
                  style={{ zIndex: "2" }}
                  src="Images/phone2.svg"
                  alt="none"
                />
                <span className="circles2">
                  <p className="circlesText">
                    {t("work_section.choose_vibe_desc")}
                  </p>
                  <img className="svg" src={Location} alt="none" />
                </span>
              </div>
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "transparent", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  transparent" }}
            date={t("work_section.send_invite")}
            iconStyle={{ background: "#333333", color: "black" }}
          >
            <div className="box1">
              <div className="internalBox">
                <span className="circles1" style={{ marginRight: ".5rem" }}>
                  <p className="circlesText">
                    {t("work_section.send_invite_desc")}
                  </p>
                  <img className="svg" src={Message} alt="none" />
                </span>
                <img className="phone3" src="Images/phone3.svg" alt="none" />
              </div>
            </div>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            iconStyle={{
              ...iconSize,
              background: "#8CC9E9",
              color: "#fff",
              width: "230px",
              height: "230px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={
              <span className="circles4">
                <p className="circlesText">
                  {t("work_section.meet_in_person")}
                </p>
                <img
                  className="svg"
                  style={{ height: "auto", width: "40px" }}
                  src={Group}
                  alt="none"
                />
              </span>
            }
          />
        </VerticalTimeline>

        <div className="btn">
          <Button
            text={t("work_section.download_button")}
            textColor="white"
            click={handleRedirect}
            bgColor="#333333"
          />
        </div>
      </div>
    </>
  );
};

export default Test;
