import React, { useState, useEffect } from "react";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_URL } from "../requestMethod";
import styles from "./uploadFile.module.css";
const { Dragger } = Upload;

const UploadFile = ({ setFileUploaded }) => {
  const [fileList, setFileList] = useState([]);
  const [containerWidth, setContainerWidth] = useState("52%", "4rem");
  const [preview, setPreview] = useState("");

  const updateContainerWidth = () => {
    if (window.innerWidth <= 768) {
      setContainerWidth("90%");
    } else {
      setContainerWidth("52%");
    }
  };

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);
    return () => window.removeEventListener("resize", updateContainerWidth);
  }, []);

  const customRequest = async ({ file, onSuccess, onError }) => {
    setPreview(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${BASE_URL}/util/uploadFile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLIENT_TOKEN}`, // Replace with your actual token retrieval method
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        onSuccess(response.data);
        console.log("response.data.data", response.data);
        const url = response?.data?.url;
        console.log("url: ", response.data);
        setFileUploaded([url]); // Adjust if managing multiple files
        message.success(`${file.name} file uploaded successfully.`);
        setPreview(URL.createObjectURL(file));
      } else {
        throw new Error("Not able to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      onError(error);
      message.error(`${file.name} file upload failed.`);
    }
  };

  const handleRemove = (e) => {
    e.preventDefault();
    setPreview("");
  };

  return (
    <>
      <div className={styles.formImage}>
        {preview ? (
          <div className={styles.preview}>
            {" "}
            <img height={"auto"} width={"100%"} src={preview} alt="img" />
            <button onClick={handleRemove} className={styles.deleteIcon}>
              <img src="Images/delete.svg" alt="" />
            </button>
          </div>
        ) : (
          <Dragger
            name="file"
            fileList={fileList}
            customRequest={customRequest}
            onChange={(info) => {
              const { status } = info.file;
              if (status !== "uploading") {
                setFileList([info.file]); // Manage only the latest file
              }
            }}
            onRemove={() => {
              setFileList([]);
            }}
          // style={{
          //   width: containerWidth,
          //   height: '4rem'
          //   // margin: "auto",
          //<InboxOutlined style={{ fontSize: "10px", color: "#40a9ff" }} />
          // }}
          >
            <div className={styles.imageFormText}>
              <p className={styles.icon}>
                {/* <InboxOutlined /> */}
                <i className="fa fa-upload"></i>
              </p>
              <p className={styles.title}> Drag file here or click to upload</p>
              {/* <p className={styles.description}>
                Support for a single upload. Strictly prohibit from uploading
                company data or other band files
              </p> */}
            </div>
          </Dragger>
        )}
      </div>
    </>
  );
};

export default UploadFile;
