import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import i18n hook
import styles from "../../pages/Register/register.module.css";
import { useDisclosure } from "@chakra-ui/react";

const LegalRepresentative = ({ setLocation }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    fullName: Yup.string().required(
      t("register.requiredField", { field: t("register.firstName") })
    ),
    lastName: Yup.string()
      .matches(
        /^[A-Za-z]+$/,
        t("register.invalidField", { field: t("register.lastName") })
      )
      .required(t("register.requiredField", { field: t("register.lastName") })),
    email: Yup.string()
      .email(t("register.invalidField", { field: t("register.email") }))
      .required(t("register.requiredField", { field: t("register.email") })),
    mobile: Yup.string()
      .matches(
        /^\d{7,15}$/,
        t("register.invalidField", { field: t("register.mobileNumber") })
      )
      .required(
        t("register.requiredField", { field: t("register.mobileNumber") })
      ),
    jobPosition: Yup.string().required(
      t("register.requiredField", { field: t("register.jobPosition") })
    ),
  });

  const [initialValues, setInitialValues] = useState({
    fullName: "",
    lastName: "",
    email: "",
    mobile: "",
    jobPosition: "",
  });

  const handleSubmit = (values) => {
    console.log("values", values);
    localStorage.setItem("legalRepresentativeData", JSON.stringify(values));
    setLocation("form3");
  };

  const handleBack = () => {
    setLocation("form1");
  };

  return (
    <div className={styles.container}>
      <div className={styles.main_form}>
        <div className={styles.sub_form}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched }) => (
              <Form>
                <div className={styles.formMargin}>
                  <div className={styles.paging}>
                    <p className={styles.loc}>
                      {t("register.legalRepresentative")}
                    </p>
                    <p className={styles.count}>{t("register.paging")}</p>
                  </div>
                  <div className={styles.underLineBorderTwo}>
                    <img src="Images/underlineTwo.svg" alt="" />
                  </div>
                </div>
                <div className={styles.legal}>
                  <div className={styles.legal_a}>
                    <label className={styles.label}>
                      {t("register.firstName")}
                      <sup>*</sup>
                    </label>
                    <Field
                      name="fullName"
                      type="text"
                      className={styles.form_field}
                      placeholder={t("register.placeholders.firstName")}
                    />
                    {errors.fullName && touched.fullName && (
                      <div className={styles.error}>{errors.fullName}</div>
                    )}

                    <label className={styles.label}>
                      {t("register.email")}
                      <sup>*</sup>
                    </label>
                    <Field
                      name="email"
                      type="email"
                      className={styles.form_field}
                      placeholder={t("register.placeholders.email")}
                    />
                    {errors.email && touched.email && (
                      <div className={styles.error}>{errors.email}</div>
                    )}
                  </div>

                  <div className={styles.legal_b}>
                    <label className={styles.label}>
                      {t("register.lastName")}
                      <sup>*</sup>
                    </label>
                    <Field
                      name="lastName"
                      type="text"
                      className={styles.form_field}
                      placeholder={t("register.placeholders.lastName")}
                    />
                    {errors.lastName && touched.lastName && (
                      <div className={styles.error}>{errors.lastName}</div>
                    )}

                    <label className={styles.label}>
                      {t("register.mobileNumber")}
                      <sup>*</sup>
                    </label>
                    <Field
                      name="mobile"
                      type="number"
                      className={styles.form_field}
                      placeholder={t("register.placeholders.mobileNumber")}
                    />
                    {errors.mobile && touched.mobile && (
                      <div className={styles.error}>{errors.mobile}</div>
                    )}
                  </div>
                </div>
                <div className={styles.job_pos}>
                  <label className={styles.label}>
                    {t("register.jobPosition")}
                    <sup>*</sup>
                  </label>
                  <Field
                    name="jobPosition"
                    type="text"
                    className={styles.form_field}
                    placeholder={t("register.placeholders.jobPosition")}
                  />
                  {errors.jobPosition && touched.jobPosition && (
                    <div className={styles.error}>{errors.jobPosition}</div>
                  )}
                </div>
                <div className={styles.continue}>
                  <button type="button" onClick={handleBack}>
                    {t("register.back")}
                  </button>
                  <button type="submit">{t("register.next")}</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LegalRepresentative;
