import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import styles from "./addDetails.module.css";
import { message } from "antd";
import LegalRepresentative from "./LegalRepresentative";
import Thanking from "./Thanking";
import CardComponent from "./CardComponent";
import AdDetails from "./AdDetails";
import Select from "react-select";
import LocationPicker from "../../components/Location/LocationPicker";
import BannerStyle from "./banner.module.css";
import ModernSelect from "react-select";
import { useTranslation } from "react-i18next";
const SendRequest = () => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [location, setLocation] = useState("sendRequest");
  const [companyDetails, setCompanyDetails] = useState();
  const [addDetails, setAddDetails] = useState({});
  const [legalRepDetails, setLegalRepDetails] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  // const [targetFrom, setTargetFrom] = useState("");
  // const [targetTo, setTargetTo] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState(null);
  const [addressError, setAddressError] = useState(null);
  // const [latitude, setLatitude] = useState(null);
  // const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(40.7128);
  const [longitude, setLongitude] = useState(-74.006);
  const [userAddress, setUserAddress] = useState(null);
  // const [selectedAds, setSelectedAds] = useState("");
  console.log("address", address);
  console.log("legalRepDetails", legalRepDetails);

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required(t("ads.compnay_name_required"))
      .matches(/^[A-Za-z\s]+$/, t("ads.company_name_validation")),

    category: Yup.string().required(t("ads.category_required")),

    // Validation for Location
    // location: Yup.string()
    //   .required("Business location is required")
    //   .test(
    //     "location-test",
    //     "Please select a valid location",
    //     function (value) {
    //       return !!userAddress || value !== "";
    //     }
    //   ),
  });

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };
  // const handleTargetChange = (value) => {
  //   setTargetFrom(value);
  //   setTargetTo("");
  // };
  // const handleTargetChangeTo = (value) => {
  //   setTargetTo(value);
  // };

  useEffect(() => {
    if (!!latitude && !!longitude) {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => setAddress(data.address));
    }
  }, [latitude, longitude]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "10px",
      height: "58px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "1rem",
      backgroundColor: "#f7fcff",
      // backgroundColor: "cyan",
      fontWeight: "500",
      paddingLeft: "14px",
      backgroundImage: "url(../../../public/Images/dropdown.svg)",

      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "50px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      // width: "100%",
      margin: "0px",
      borderRadius: "1rem",
      // backgroundColor: "#e5e5e5",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      // height: "55px",
      //backgroundColor: "#e5e5e5",
      //backgroundColor: '#f7fcff',
      color: "black",
      cursor: "pointer",
      borderRadius: "2rem",
      borderBottom: "1px solid white",
      fontFamily: "GalanoGrotesque-Medium",
      // fontSize: "1.2rem",
      // fontWeight: "500",
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
      "@media (max-width: 767px)": {
        fontSize: "4vw",
        height: "12vw",
      },
      ":last-child": {
        borderBottom: "none",
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default arrow and line
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const dropdownOptions = [
    { value: "Restaurant", label: "Restaurant" },
    { value: "Bar", label: "Bar" },
    { value: "Cafe", label: "Cafe" },
  ];

  if (location === "adDetails") {
    return (
      <AdDetails
        setLocation={setLocation}
        companyDetails={companyDetails}
        legalRepDetails={legalRepDetails}
        addDetails={addDetails}
        setAddDetails={setAddDetails}
        // setSelectedAds={setSelectedAds}
        // selectedAds={selectedAds}
      />
    );
  }

  if (location === "legalRep") {
    return (
      <LegalRepresentative
        setLocation={setLocation}
        setLegalRepDetails={setLegalRepDetails}
        legalRepDetails={legalRepDetails}
        companyDetails={companyDetails}
        addDetails={addDetails}
        address={address}
        // selectedAds={selectedAds}
      />
    );
  }

  if (location === "thanking") {
    return <Thanking setLocation={setLocation} />;
  }
  console.log("userAddress", userAddress);
  const handleSubmit = (values) => {
    userAddress.house_number = address?.house_number;
    userAddress.postcode = address?.postcode;
    userAddress.street = address?.road;

    const data = {
      companyName: values.companyName,
      address: userAddress,
      addressObject: userAddress,
      category: selectedOption.value,
      city: address.city,
    };

    if (!values.companyName) {
      message.error(t("ads.compnay_name_required"));
    } else if (!/^[A-Za-z\s]+$/.test(values.companyName)) {
      message.error(t("ads.company_name_validation"));
    } else if (!selectedOption.value) {
      message.error(t("ads.category_required"));
    } else if (!userAddress) {
      message.error("Please enter business location");
    } else {
      console.log("data", data);
      setCompanyDetails(data);
      setLocation("adDetails");
    }
  };

  return (
    <div className={styles.container}>
      <div className={BannerStyle.banner}>
        <div className={BannerStyle.bannerTitle}>{t("ads.banner_title")}</div>
        <div className={BannerStyle.bannerSubTitle}>
          {" "}
          {t("ads.banner_subtitle")}
        </div>
      </div>
      <div className={styles.yourBenefits}>
        <p>{t("ads.your_benefits")}</p>
      </div>
      <CardComponent />
      <div className={styles.main_form}>
        <div className={styles.sub_form}>
          <Formik
            initialValues={{
              companyName: "",
              companyTaxNumber: "",
              address: "",
              businessEmail: "",
              businessMobile: "",
              category: "",
              location: "",
              city: "",
              postCode: "",
              houseNumber: "",
              targetGroup: "",
              ...companyDetails,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className={styles.headingMargin}>
                  <div className={styles.headingLeft}>
                    <p className={styles.loc}>{t("ads.send_request")}</p>
                    <div className={styles.underLineBorder}>
                      <img src="Images/underline.svg" alt="" />
                    </div>
                  </div>
                  <div className={styles.headingRight}>
                    <img src="Images/oneByThree.svg" alt="" />
                  </div>
                </div>
                <div className={styles.location}>
                  <div className={styles.location_a}>
                    <label className={styles.label}>
                      {t("ads.company_name")}

                      <sup>*</sup>
                    </label>
                    <Field
                      name="companyName"
                      type="text"
                      className={styles.form_field}
                      placeholder={t("ads.placeholder_company_name")}
                    ></Field>
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className={styles.errorMessage}
                    />
                    <label className={styles.label}>
                      {t("ads.business_location")} <sup>*</sup>
                    </label>
                    <div style={{ marginBottom: "1rem" }}></div>
                    {/* <br></br> */}

                    <LocationPicker
                      setLatitude={setLatitude}
                      latitude={latitude}
                      setLongitude={setLongitude}
                      longitude={longitude}
                      setUserAddress={setUserAddress}
                      userAddress={userAddress}
                      setError={setError}
                      error={error}
                      addressError={addressError}
                      setAddressError={setAddressError}
                    />

                    {errors.location && touched.location && (
                      <div className={styles.errorMessage}>
                        {errors.location}
                      </div>
                    )}
                  </div>

                  <div className={styles.location_b}>
                    <label className={styles.label}>
                      {t("ads.category")}
                      <sup>*</sup>
                    </label>
                    <br></br>

                    <div style={{ marginTop: "1rem", marginBottom: "0.8rem" }}>
                      <Select
                        options={dropdownOptions}
                        styles={customStyles}
                        value={selectedOption}
                        // onChange={handleOptionChange}
                        onChange={(selected) => {
                          setSelectedOption(selected); // update the selected option state
                          setFieldValue(
                            "category",
                            selected ? selected.value : ""
                          ); // update Formik state
                        }}
                        placeholder={t("ads.placeholder_category")}
                        components={{
                          // Add a custom dropdown indicator with your image
                          IndicatorSeparator: () => null, // Hide the separator
                          DropdownIndicator: () => (
                            <img
                              src="Images/dropdown5.svg"
                              alt="Custom Dropdown Indicator"
                              style={{
                                width: "15px",
                                height: "2vw",
                                marginRight: "1vw",
                                // background: '#f7fcff'
                              }} // Adjust the dimensions as needed
                            />
                          ),
                        }}
                      />
                      {errors.category && touched.category && (
                        <div className={styles.errorMessagedropdown}>
                          {errors.category}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.form_btn}>
                  <div></div>
                  <button type="submit" onClick={() => handleSubmit}>
                    {t("ads.nextButton")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SendRequest;
