import styles from "./faq.module.css";
import Accordion from "../../components/accordion/Accordion";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
const FAQPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 }); // Smooth scroll to top
  }, []);
  return (
    <>
      <Helmet>
        <title>FAQ - NETME</title>
        <meta
          name="description"
          content="NETME: Your Passport to Offline Connections"
        />
      </Helmet>

      <div className={styles.main}>
        <Accordion />
      </div>
    </>
  );
};

export default FAQPage;
