import React from "react";
import styles from "./index.module.css";
import HeroSection from "../../components/Index/heroSection/HeroSection";
import FeaturesSection from "../../components/Index/featuresSection/FeaturesSection";
import AboutMeSection from "../../components/Index/aboutMeSection/AboutMeSection";
import FAQSection from "../../components/Index/FAQSection/FAQSection";
import CarouselSection from "../../components/Index/carouselSection/CarouselSection";
import ExperienSection from "../../components/Index/experienceSection/ExperienSection";
import SubscribeSection from "../../components/Index/subscribeSection/SubscribeSection";
import FollowUsSection from "../../components/Index/followUs/FollowUsSection";
import WorkSection from "../../components/Index/workSection/WorkSection";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
const IndexPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 }); // Smooth scroll to top
  }, []);
  return (
    <>
      <Helmet>
        <title>The App - NETME</title>
        <meta
          name="description"
          content="NETME: Your Passport to Offline Connections"
        />
      </Helmet>

      <div className={styles.container}>
        <HeroSection />
        <FeaturesSection />
        <AboutMeSection />
        <WorkSection />
        <FAQSection />
        <CarouselSection />
        <ExperienSection />
        <SubscribeSection />
        <FollowUsSection />
      </div>
    </>
  );
};

export default IndexPage;
