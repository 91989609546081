import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./subscribeSection.module.css";
import { message } from "antd";
import { publicRequest, sendEmail } from "../../../requestMethod";
import ReCAPTCHA from "react-google-recaptcha";

const SubscribeSection = () => {
  const { t } = useTranslation();
  const recaptcha = useRef(null);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const captchaValue = recaptcha.current.getValue();
      if (!captchaValue) {
        alert("Please verify the reCAPTCHA!");
      } else {
        const res = await publicRequest.post("/verify", { captchaValue });
        if (res?.data?.success) {
          // make form submission
          await sendEmail
            .post("/users/saveNewsletter", JSON.stringify(formData))
            .then(() => {
              message.success(t("subscribeSection.successMessage"));
              setFormData({ email: "" }); // Reset email field
            })
            .catch((err) => {
              // if (err.status === 409) {
              //   message.error(err.data.data.message);
              // }
              console.log("err", err);
              const errorMessage =
                err.response?.data?.message ||
                err.response?.data?.data?.message ||
                err.response?.data?.error ||
                "An error occurred";
              message.error(errorMessage);
            });
          // alert("Form submission successful!");
        } else {
          alert("reCAPTCHA validation failed!");
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      const errorMessage =
        error.response?.data?.error || t("subscribeSection.errorMessage");
      message.error(errorMessage);
    }
  };

  return (
    <div className={styles.main}>
      <p className={styles.title}>{t("subscribeSection.title")}</p>
      <div>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className={styles.input}
          placeholder={t("subscribeSection.placeholder")}
          pattern="[^ @]*@[^ @]*"
        />
      </div>
      <div className={styles.recaptcha_btn}>
        <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
      </div>
      <button onClick={handleSubmit} className={styles.btn}>
        {t("subscribeSection.buttonText")}
      </button>
    </div>
  );
};

export default SubscribeSection;
