import React, { useRef, useState, useEffect } from "react";
import styles from "./addDetails.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import CardComponent from "./CardComponent";
import fullAdPreview from "../../assets/fullAdPreview.png";
import Select, { components } from "react-select";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
//import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import DatePicker from "./DatePicker";
import "../../../src/App.css";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useTranslation } from "react-i18next";

function AdDetails({
  setLocation,
  companyDetails,
  legalRepDetails,
  addDetails,
  setAddDetails,
}) {
  const { t } = useTranslation();
  const [targetFrom, setTargetFrom] = useState("");
  // const [selectedOption, setSelectedOption] = useState("");
  const [startDate, setStartDate] = useState(null);

  const [targetTo, setTargetTo] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const adLabelStyle = {
    color: "blue",
    marginLeft: "5px",
    fontSize: "20px",
    textDecoration: "underline",
  };

  const calendarRef = useRef(null);

  // Styles for the image container
  const imageContainerStyle = {
    position: "absolute",
    top: "58%",
    right: "25%",
    zIndex: 1000,
  };

  // Styles for the preview image itself
  const imageStyle = {
    width: "350px",
    backgroundAttachment: "fixed",
    borderRadius: "0.7rem",
    border: "2px solid #ddd",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)",
    "@media(max-width: 767px)": {
      width: "250px",
      // height: "150px",
    },
  };
  // Handle mouse enter and leave events to toggle hover state
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "10px",
      height: "55px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "0..9rem",
      backgroundColor: "#f7fcff",
      fontFamily: "GalanoGrotesque-Medium",

      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "50px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0",
      backgroundColor: "#e5e5e5",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "50px", // Set the desired height for each option item
      backgroundColor: "#e5e5e5",
      color: "black",
      cursor: "pointer",
      borderBottom: "1px solid white",
      textAlign: "center",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: "1.2rem",
      "@media (max-width: 767px)": {
        fontSize: "3vw",
        height: "7vw",
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default arrow and line
    }),
  };

  const dropdownOptions = [
    // { value: "Feed", label: "Feed" },
    // { value: "Suggestion", label: "Suggestion" },
    { value: "Fullscreen", label: "Fullscreen" },
  ];
  const handleTargetChange = (value) => {
    setTargetFrom(value);
    setTargetTo("");
  };
  const handleTargetChangeTo = (value) => {
    setTargetTo(value);
  };
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      minHeight: "10px",
      height: "55px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "1rem",
      backgroundColor: "#f7fcff",

      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "50px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0",
      backgroundColor: "#f7fcff",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "50px", // Set the desired height for each option item
      //backgroundColor: "#e5e5e5",
      backgroundColor: "#c2def0",
      color: "black",
      cursor: "pointer",
      borderRadius: "0.5rem",
      borderBottom: "1px solid white",
      textAlign: "center",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: "1.2rem",
      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "7vw",
      },
      ":last-child": {
        borderBottom: "none",
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default arrow and line
    }),
  };

  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    return date;
  };

  const minDate = getMinDate();

  const optionsArray = [];

  for (let i = 18; i <= 60; i++) {
    optionsArray.push({ value: i.toString(), label: i.toString() });
  }
  const optionsArray2 = [];

  for (let i = targetFrom.value; i <= 60; i++) {
    optionsArray2.push({ value: i.toString(), label: i.toString() });
  }
  console.log("addDetails", addDetails);

  // const handleSubmit = async (values) => {
  //   // make form submission
  //   console.log("values", values);
  //   if (!values.title) {
  //     message.error("Please enter title");
  //   }
  //   //  else if (!values.releaseDate) {
  //   //   message.error("Please select releaseDate");
  //   // }
  //   else if (!values.body) {
  //     message.error("Please enter body");
  //   }
  //   else {
  //     values.adType = "Fullscreen";
  //     values.targerGroupFrom = targetFrom.value;
  //     values.targetGroupTo = targetTo.value;
  //     setAddDetails(values);
  //     setLocation("legalRep");
  //   }
  // };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, t("ads.adTitleMinLengthError"))
      .required(t("ads.adTitleError")),
    releaseDate: Yup.date()
      .required(t("ads.campaignStartDateError"))
      .min(new Date(), t("ads.campaignStartDatePastError")),
    body: Yup.string()
      .min(10, t("ads.bodyMinLengthError"))
      .required(t("ads.bodyError")),
    targetGroupFrom: Yup.string().required(t("ads.targetGroupFromError")),
    targetGroupTo: Yup.string().required(t("ads.targetGroupToError")),
  });

  const handleSubmit = (values) => {
    values.adType = "Fullscreen";
    values.targetGroupFrom = targetFrom.value;
    values.targetGroupTo = targetTo.value;
    setAddDetails(values);
    setLocation("legalRep");
  };

  const handleBack = () => {
    setLocation("sendRequest");
  };

  const today = new Date();
  const getDefaultDate = () => {
    const futureDate = new Date(today);
    futureDate.setDate(futureDate.getDate() + 3); // Add 3 days to today's date
    return {
      year: futureDate.getFullYear(),
      month: futureDate.getMonth() + 1, // Months are 0-based in JavaScript
      day: futureDate.getDate(),
    };
  };

  const [selectedDate, setSelectedDate] = useState(getDefaultDate);

  useEffect(() => {
    // Get the current date
    const today = new Date();

    // Add 3 days to the current date
    today.setDate(today.getDate() + 3);

    // Set the dateValue state to 3 days ahead
    setDateValue(today);
  }, []);
  const [dateValue, setDateValue] = useState(null);

  return (
    <div className={styles.container}>
      <div className={styles.form_pic_con}>
        <img src="Images/adFormOne.svg" alt="" />
      </div>

      <div className={styles.yourBenefits}>
        <p>{t("ads.your_benefits")}</p>
      </div>
      <CardComponent />

      <div className={styles.main_form}>
        <div className={styles.sub_form}>
          <Formik
            initialValues={{
              title: "",
              releaseDate: "",
              body: "",
              adType: "Fullscreen",
              targetGroupFrom: "",
              targetGroupTo: "",
              ...addDetails,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log("i am triggered", values);
              handleSubmit(values);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className={styles.headingMargin}>
                  <div className={styles.headingLeft}>
                    <p className={styles.l_r}>Ad details</p>
                    <img src="Images/underline.svg" alt="" />
                  </div>
                  <div className={styles.headingRight}>
                    <img src="Images/twoByThree.svg" alt="" />
                  </div>
                </div>
                <div className={styles.legal}>
                  <div className={styles.legal_a}>
                    <label className={styles.label}>
                      {t("ads.adTypeLabel")}
                      <sup>*</sup>
                    </label>
                    <br></br>
                    <Field
                      name="title"
                      type="text"
                      className={styles.form_field}
                      placeholder={t("ads.adTitlePlaceholder")}
                    ></Field>
                    <ErrorMessage
                      name="title"
                      component="div"
                      className={styles.errorAdDetials}
                    />
                    <br></br>
                    <label className={styles.label}>
                      {t("ads.campaignStartDateLabel")}
                      <sup>*</sup>
                    </label>
                    <br></br>
                    {/* <Field
                    name="releaseDate"
                    type="date"
                    min={new Date(minDate).toISOString().split("T")[0]}
                    className={styles.form_field_date}
                  ></Field> */}

                    {/* <DatePicker
                    value={selectedDate || getDefaultDate()}
                    onChange={setSelectedDate}
                    inputPlaceholder="Select a date"
                    shouldHighlightWeekends
                    minimumDate={getDefaultDate()}
                    renderInput={renderCustomInput}
                  /> */}
                    {/* 
                  <div className={styles.form_field_date}>
                    <DatePickerComponent
                      placeholder='Enter Date'
                      value={dateValue}
                      min={dateValue}

                    ></DatePickerComponent> */}

                    {/* <CalendarComponent id="calendar" placeholder="mm/dd/yyyy" /> */}

                    <div className={styles.form_field_date}>
                      <DatePicker
                        value={values.releaseDate}
                        onChange={(date) => setFieldValue("releaseDate", date)}
                      />
                    </div>

                    {/* </div> */}

                    <br></br>
                    <br></br>
                    <label className={styles.label}>
                      {t("ads.targetGroupLabel")}
                      <sup>*</sup>
                    </label>
                    <br></br>
                    <div
                      style={{ marginTop: "1vw" }}
                      className={styles.targetDiv}
                    >
                      <p> {t("ads.targetGroupAgeLabel")}</p>
                      <span>
                        <Select
                          options={optionsArray}
                          styles={customStyles1}
                          value={targetFrom}
                          // onChange={handleTargetChange}

                          onChange={(value) => {
                            setTargetFrom(value);
                            setFieldValue("targetGroupFrom", value.value);
                          }}
                          placeholder={t("ads.targetGroupFromPlaceholder")}
                        />
                        <Select
                          options={optionsArray2}
                          styles={customStyles1}
                          value={targetTo}
                          // onChange={handleTargetChangeTo}
                          onChange={(value) => {
                            setTargetTo(value);
                            setFieldValue("targetGroupTo", value.value);
                          }}
                          isDisabled={!targetFrom.value}
                          placeholder={t("ads.targetGroupToPlaceholder")}
                        />
                      </span>
                    </div>
                    <ErrorMessage
                      name="targetGroupFrom"
                      component="div"
                      className={styles.errorAdDetials}
                    />
                    <ErrorMessage
                      name="targetGroupTo"
                      component="div"
                      className={styles.errorAdDetials}
                    />
                  </div>

                  <div className={styles.legal_b}>
                    <div>
                      <label className={styles.label}>
                        {t("ads.adTypeLabel")}
                        <sup>*</sup>
                      </label>
                      <br></br>
                      <br></br>
                      {/* <Select
                      options={dropdownOptions}
                      styles={customStyles}
                      value={selectedOption}
                      onChange={handleOptionChange}
                      placeholder="Select ad type"
                      components={{ Option: CustomOption }}
                    /> */}
                      <a
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={adLabelStyle}
                      >
                        {t("ads.adTypeValue")}
                      </a>
                      {isHovered && (
                        <div style={imageContainerStyle}>
                          {/* Replace with actual image path */}
                          <img
                            src={fullAdPreview}
                            alt="Fullscreen Ad Preview"
                            style={imageStyle}
                          />
                        </div>
                      )}
                    </div>
                    <br></br>
                    <label className={styles.label}>
                      {t("ads.bodyLabel")}
                      <sup>*</sup>
                    </label>

                    <br></br>
                    <Field
                      name="body"
                      component="textarea"
                      rows="5"
                      className={styles.form_fieldTextArea}
                    ></Field>
                    <ErrorMessage
                      name="body"
                      component="div"
                      className={styles.errorAdDetials}
                    />
                  </div>
                </div>
                <div className={styles.form_btn}>
                  <button onClick={handleBack}>{t("ads.backButton")}</button>
                  <button type="submit">{t("ads.nextButton")}</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AdDetails;
