import React from "react";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import styles from "./featuresSection.module.css";

const FeaturesSection = () => {
  const { t } = useTranslation(); // Access the translation function

  return (
    <div className={styles.main}>
      <div className={styles.box}>
        <h1 className={styles.title}>{t("features_section.discover")}</h1>
        <div className={styles.discover}>
          <img
            src="Images/discover.svg"
            style={{ marginTop: "-.8rem" }}
            alt="discover"
          />
        </div>
      </div>

      <div className={styles.box}>
        <h1 className={styles.title}>{t("features_section.connect")}</h1>
        <div className={styles.connect}>
          <img src="Images/connect.svg" alt="connect" />
        </div>
      </div>

      <div className={styles.box}>
        <h1 className={styles.title}>{t("features_section.meet_up")}</h1>
        <div className={styles.meet}>
          <img
            style={{ marginTop: ".5rem" }}
            src="Images/meet.svg"
            alt="meet"
          />
        </div>
      </div>

      <div className={styles.box}>
        <h1 className={styles.title}>
          {t("features_section.collect_rewards")}
        </h1>
        <div className={styles.reward}>
          <img
            style={{ marginTop: "-.3rem" }}
            src="Images/reward.jpg"
            alt="reward"
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
