import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./CarouselSection.module.css";

const CarouselSection = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3, // Show 3 slides at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviews = t("carouselSection.reviews", { returnObjects: true }); // Load reviews from JSON

  return (
    <div>
      <p className={styles.title}>{t("carouselSection.title")}</p>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div className={styles.container} key={index}>
            <div className={styles.box}>
              <div className={styles.image}>
                <p>{review.img}</p>
              </div>
              <p className={styles.detail}>{review.name}</p>
            </div>
            <div className={styles.stars}>
              <img
                style={{ width: "1.5rem", height: "auto" }}
                src="/Images/star.png"
                alt="star"
              />
              <img
                style={{ width: "1.5rem", height: "auto" }}
                src="/Images/star.png"
                alt="star"
              />
              <img
                style={{ width: "1.5rem", height: "auto" }}
                src="/Images/star.png"
                alt="star"
              />
              <img
                style={{ width: "1.5rem", height: "auto" }}
                src="/Images/star.png"
                alt="star"
              />
              {review.name !== "smply_verena" && (
                <img
                  style={{ width: "1.5rem", height: "auto" }}
                  src="/Images/star.png"
                  alt="star"
                />
              )}
            </div>
            <p className={styles.clientName}>{review.message}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselSection;
