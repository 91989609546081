import React, { useEffect, useState } from "react";
import { AutoComplete, Input, Spin } from "antd";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
const libraries = ["places", "geometry"];

const LocationPicker = ({
  setUserAddress,
  setLatitude,
  setLongitude,
  userAddress,
  error,
  setError,
  addressError,
  setAddressError,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY, // Replace with your Google Maps API key
    libraries,
  });

  if (!isLoaded) return <h1>loading..</h1>;

  return (
    <PlacesAutocomplete
      error={error}
      setError={setError}
      addressError={addressError}
      setAddressError={setAddressError}
      setLatitude={setLatitude}
      setLongitude={setLongitude}
      userAddress={userAddress}
      setUserAddress={setUserAddress}
    />
  );
};

const PlacesAutocomplete = ({
  setUserAddress,
  setLatitude,
  setLongitude,
  userAddress,
  error,
  setError,
  addressError,
  setAddressError,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    initialValue: userAddress?.address || "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (userAddress && userAddress.address) {
      if (!/\d+/.test(userAddress.address)) {
        setAddressError("");
        setError("Add your house number");
        setUserAddress(null); // Clear the address
        setLatitude(null); // Clear latitude
        setLongitude(null); // Clear longitude
        return;
      }else{
        setError(null);
        setValue(userAddress.address, false);
        setAddressError("");
      }
    }
  }, [userAddress]);

  useEffect(() => {
    if (value) {
      if (/\d+/.test(value)) {
        setError("Choose your address from the dropdown");
        return;
      }
    }
    if (!value) {
      setError(null);
    }
  }, [value]);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const addressComponents = results[0].address_components;

      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const country = addressComponents.find((component) =>
        component.types.includes("country")
      )?.long_name;

      const { lat, lng } = await getLatLng(results[0]);
      setUserAddress({
        city,
        country,
        address,
        lng,
        lat,
      });
      setLatitude(lat);
      setLongitude(lng);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const onSearch = (value) => {
    setValue(value);
  };

  const customStyles = {
    container: {
      width: "100%", // Adjust the width as needed
      marginLeft: "0px",
    },
    input: {
      // Custom input styles here
      width: "100%",
      height: 55,
      background: "transparent",
      border: "none",
    },
    suggestionsList: {
      backgroundColor: "white",
      marginTop: "4px",
      position: "absolute",
      zIndex: 9999,
    },
    suggestion: {
      cursor: "pointer",
      padding: "10px",
    },
    control: {
      width: "100%",
      minHeight: "10px",
      height: "55px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: "14px", // Set a default font size
      backgroundColor: "#f7fcff",
      fontWeight: "500",
      backgroundImage: "url(../../../public/Images/dropdown.svg)",
    },
  };

  return (
    <div style={customStyles.container}>
      <AutoComplete
        style={{ ...customStyles.control, width: 500 }}
        options={data.map((place) => ({
          value: place.description,
        }))}
        onSelect={handleSelect}
        onSearch={onSearch}
        value={value}
        placeholder={t("register.searchAddress")}
        filterOption={(inputValue, option) =>
          option.value.toLowerCase().includes(inputValue.toLowerCase())
        }
      >
        <Input
          style={customStyles.input}
          allowClear
          suffix={
            <img
              src="Images/dropdown5.svg"
              alt="Custom Dropdown Indicator"
              style={{
                width: "15px",
                height: "2vw",
                marginRight: "1vw",
                // background: '#f7fcff'
              }} // Adjust the dimensions as needed
            />
          }
        />
      </AutoComplete>
      {error && (
        <div style={{ color: "red", marginTop: "8px", marginLeft: "1rem" }}>
          {error}
        </div>
      )}
      {addressError && (
        <div style={{ color: "red", marginTop: "8px", marginLeft: "1rem" }}>
          {addressError}
        </div>
      )}
    </div>
  );
};

export default LocationPicker;
