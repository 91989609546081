import React from "react";
import styles from "./heroSection.module.css";
import { useTranslation } from "react-i18next";
const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.banner}>
      <img
        className={styles.heroImg}
        src="Images/heroImgWebp.webp"
        alt="group-of-people"
      />

      <div className={styles.promoCard}>
        <h1>{t("connect_online_meet_offline")}</h1>
        <p>{t("next_adventure_starts_with_netme")}</p>

        <div className={styles.btnsection}>
          <a href="#">
            <img
              className={styles.storeButton}
              src="Images/playStore.png"
              alt="play-store-btn"
            />
          </a>
          <a href="#">
            <img
              className={styles.storeButton}
              src="Images/appStore.png"
              alt="app-store-btn"
            />
          </a>
        </div>
      </div>

      <div className={styles.promoCardMobile}>
        <h1>CONNECT ONLINE,</h1>
        <h1>MEET OFFLINE</h1>

        <p>{t("next_adventure_starts_with_netme")}</p>
        <div className={styles.btnsectionMobile}>
          <a href="#">
            <img
              className={styles.storeButtonMobile}
              src="Images/playStore.png"
              alt="play-store-btn"
            />
          </a>
          <a href="#">
            <img
              className={styles.storeButtonMobile}
              src="Images/appStore.png"
              alt="app-store-btn"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
