import React from "react";
import styles from "./footer.module.css";
import heart from "../../assets/icons/Heart.svg";
import fb from "../../assets/icons/facebook.svg";
import insta from "../../assets/icons/instagram.svg";
import twitter from "../../assets/icons/twitter.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.footerContainer}>
        <div className={styles.footer_pic}>
          <img
            src="Images/logo.svg"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/index")}
            alt="Logo"
          />
        </div>
        <div className={styles.footer_txt}>
          <span>{t("footer.made_with")}</span>
          <img src={heart} alt="Heart" />
          <span className={styles.spanRight}>{t("footer.in_munich")}</span>
        </div>
        <div className={styles.footer_frame}>
          <a href="https://www.instagram.com/netmeapp/">
            <img src={insta} alt="Instagram" />
          </a>
          <a href="https://twitter.com/netmeapp">
            <img src={twitter} alt="Twitter" />
          </a>
          <a href="https://www.facebook.com/netmeapp">
            <img src={fb} alt="Facebook" />
          </a>
        </div>
      </div>
      <div className={styles.footer_items}>
        <Link to="/">
          <span className={styles.menu}>{t("footer.menu.app")}</span>
        </Link>
        <Link to="/faq">
          <span className={styles.menu}>{t("footer.menu.faq")}</span>
        </Link>
        <Link to="/about">
          <span className={styles.menu}>{t("footer.menu.about")}</span>
        </Link>
        <Link to="/becomeapartner">
          <span className={styles.menu}>{t("footer.menu.become_partner")}</span>
        </Link>
        <Link to="/register">
          <span className={styles.menu}>{t("footer.menu.ads")}</span>
        </Link>
        <Link to="/terms-conditions">
          <span className={styles.menu}>
            {t("footer.menu.terms_conditions")}
          </span>
        </Link>
        <Link to="/privacy-policy">
          <span className={styles.menu}>{t("footer.menu.privacy_policy")}</span>
        </Link>
        <Link to="/legal-disclosure">
          <span className={styles.menu}>
            {t("footer.menu.legal_disclosure")}
          </span>
        </Link>
      </div>
      <p className={styles.copyright}>{t("footer.copyright")}</p>
    </div>
  );
};

export default Footer;
