import React, { useRef } from "react";
import styles from "./addDetails.module.css";
import { Formik, Form, Field } from "formik";
import { message } from "antd";
import CardComponent from "./CardComponent";
import { sendEmail, userRequest } from "../../requestMethod";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
function LegalRepresentative({
  setLocation,
  setLegalRepDetails,
  legalRepDetails,
  companyDetails,
  selectedAds,
  addDetails,
}) {
  const { t } = useTranslation();
  const recaptcha = useRef(null);
  const handleSubmit = async (values) => {
    console.log("va", values);
    setLegalRepDetails(values);
    const captchaValue = recaptcha.current.getValue();
    console.log("captchaValue", captchaValue);

    if (!captchaValue) {
      alert(t("legalRepresentative.error_recaptcha_failed"));
    } else {
      const data = {
        title: addDetails.title,
        body: addDetails.body,
        legalRepresentative: {
          fullName: legalRepDetails.fullName,
          email: legalRepDetails.email,
          mobile: legalRepDetails.mobile,
        },
        companyName: companyDetails.companyName,
        companyTaxNumber: legalRepDetails.companyTaxNumber,
        address: companyDetails?.address,
        addressObject: companyDetails?.address,
        businessEmail: legalRepDetails.email,
        businessMobile: legalRepDetails.mobile,
        category: companyDetails.category,
        city: companyDetails.city,
        targerGroupFrom: addDetails.targerGroupFrom,
        targetGroupTo: addDetails.targetGroupTo,
        releaseDate: addDetails.releaseDate,
        adType: addDetails.adType,
      };

      if (!values.fullName) {
        message.error(t("legalRepresentative.error_full_name"));
      } else if (!values.companyTaxNumber) {
        message.error(t("legalRepresentative.error_company_tax_no"));
      } else if (!values.email) {
        message.error(t("legalRepresentative.error_email"));
      } else if (!values.mobile) {
        message.error(t("legalRepresentative.error_mobile_no"));
      } else if (!/^\d{7,15}$/.test(values.mobile)) {
        message.error(t("legalRepresentative.error_mobile_format"));
      } else {
        console.log("data", data);

        try {
          const res = await userRequest.post("/verify", captchaValue);
          console.log("res.data", res.data);

          if (res.data.success) {
            await userRequest
              .post("ads", data)
              .then(() => {
                message.success(t("legalRepresentative.success_registration"));
                setLocation("thanking");
              })
              .catch((err) => {
                const errorMessage =
                  err.response?.data?.message ||
                  t("legalRepresentative.error_generic");
                message.error(errorMessage);
              });

            await sendEmail
              .post("/users/email", data)
              .then(() => {
                message.success(t("legalRepresentative.success_registration"));
                setLocation("thanking");
              })
              .catch((err) => {
                const errorMessage =
                  err.response?.data?.message ||
                  t("legalRepresentative.error_generic");
                message.error(errorMessage);
              });
          } else {
            message.error(t("legalRepresentative.error_recaptcha_failed"));
          }
        } catch (error) {
          message.error(t("legalRepresentative.error_generic"));
        }
      }
    }
  };

  const handleBack = () => {
    setLocation("adDetails");
  };

  return (
    <div className={styles.container}>
      <div className={styles.form_pic_con}>
        {/* <div className={styles.form_pic}>
          <p id={styles.fpt1}>You want to place ads with us?</p>
          <p id={styles.fpt3}>
            Expand your horizons for your business and place ads in the NETME
            App!
          </p>
        </div> */}
        <img src="Images/adFormOne.svg" alt="" />
      </div>

      <div className={styles.yourBenefits}>
        <p>{t("ads.your_benefits")}</p>
      </div>
      <CardComponent />

      <div className={styles.main_form}>
        <div className={styles.sub_form}>
          <Formik
            initialValues={{
              mobile: "",
              email: "",
              firstName: "",
              lastName: "",
              ...legalRepDetails,
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className={styles.headingMargin}>
                <div className={styles.headingLeft}>
                  <p className={styles.l_r}>
                    {t("register.legal_information")}
                  </p>
                  <img src="Images/underline.svg" alt="" />
                </div>
                <div className={styles.headingRight}>
                  <img src="Images/threeByThree.svg" alt="" />
                </div>
              </div>

              <div className={styles.legal}>
                <div className={styles.legal_a}>
                  <label className={styles.label}>
                    {t("register.full_name")}
                    <sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="fullName"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter Full Name"
                  ></Field>
                  <br></br>
                  <label className={styles.label}>
                    {t("register.email")}
                    <sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="email"
                    type="email"
                    className={styles.form_field}
                    placeholder={t("register.placeholders.email")}
                  ></Field>
                  <br></br>
                  <br />
                  <label className={styles.label}>
                    {t("register.companyTaxNo")}
                    <sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="companyTaxNumber"
                    type="text"
                    className={styles.form_field}
                    placeholder={t("register.placeholders.companyTaxno")}
                  ></Field>
                  <br></br>
                </div>

                <div className={styles.legal_b}>
                  {/* <label className={styles.label}>
                    Last name<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="lastName"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter Last Name"
                  ></Field> */}
                  {/* <br></br> */}
                  <label className={styles.label}>
                    {t("register.mobileNumber")}
                    <sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="mobile"
                    type="number"
                    className={styles.form_field}
                    placeholder={t("register.placeholders.mobileNumber")}
                  ></Field>
                  <br></br>
                  <div className={styles.recaptcha_btn}>
                    <ReCAPTCHA
                      ref={recaptcha}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.form_btn}>
                <button type="submit" onClick={handleBack}>
                  {t("ads.backButton")}
                </button>
                <button type="submit" onClick={() => handleSubmit}>
                  SEND REQUEST
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default LegalRepresentative;
