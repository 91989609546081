import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./experienceSection.module.css";
import Button from "../../Button";
import appStore from "../../../assets/icons/appStore.svg";
import playStore from "../../../assets/icons/playStore.svg";

const ExperienceSection = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.main}>
        <h1 className={styles.title}>{t("experienceSection.title")}</h1>
        {t("experienceSection.description", { returnObjects: true }).map(
          (paragraph, index) => (
            <p key={index} className={styles.detail}>
              {paragraph}
            </p>
          )
        )}
        <div className={styles.btnBox}>
          <Button
            text={t("experienceSection.buttonText")}
            textColor="white"
            bgColor="#8CC9E9"
          />
          <div className={styles.btnsection}>
            <a href="#">
              <img
                className={styles.storeButton}
                src={appStore}
                alt="app-store-btn"
              />
            </a>
            <a href="#">
              <img
                className={styles.storeButton}
                src={playStore}
                alt="play-store-btn"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSection;
